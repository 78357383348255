import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Ajouter_colis from './pages/ajouter-colis/ajouter-colis';
import Home from "./pages/home/home"
import MyNavbar from './components/navbar/navbar';
import Cookies from 'js-cookie';
import './App.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Connection from './pages/connection/connection';
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import axios from 'axios';
import { LoadScript } from '@react-google-maps/api';
import Dashboard from './pages/dashboard/dashboard';
import VoirOffre from './pages/voir-offre/voir_offre';
import Unauthorized from './pages/unauthorized/unauthorized';
import RequireRole from './components/requireRole';
import Creercompte from './pages/creer-compte/creer-compte';
import VerifyEmailToken from './pages/verify-email/verify-email';
import Payement from './pages/payement/payement';
import GererCompte from './pages/gerer-compte/gerer-compte';
import Suivi from './pages/dashboard/pages/suivi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import ProposerTrajet from './pages/proposer-trajet/proposer-trajet';
import VoirColisDispo from './pages/voir-colis-disponible/voir_colis_disponible';
import Paiement from './pages/payement/payement';
import Footer from './components/footer/footer';
import VoirTrajetsDispo from './pages/voir_trajets-disponible/voir_trajets_disponible';
import VoirBlogs from './pages/voir-blogs/voir_blogs';
import VoirBlog from './pages/voir-blog/voir_blog';
import ScrollToTop from './components/scroll_top';

const App = () => {
  const [auth, setAuth] = useState(useIsAuthenticated());
  const [notifications, setNotifications] = useState([]);
  const notificationIds = useRef(new Set());
  const signOut = useSignOut();
  const token = Cookies.get('_auth');
  axios.defaults.headers.common['Authorization'] = `${token}`;
  const authUser = useAuthUser();
  const [role, setRole] = useState(authUser ? authUser.userRole || "particulier" : "particulier");
  const handleSignOut = () => {
    setNotifications([])
    signOut();
    setAuth(false);
  };

  const handleSignIn = (newData) => {
    console.log('signIn :', newData);
    console.log('role :', newData.userRole);
    setAuth(newData.auth);
    setRole(newData.userRole);
  };
  const updateNotification = async (notification) => {
    try {
      if (!notification.read) {
        await axios.patch(`${process.env.REACT_APP_SERVER}/notifications/${notification._id}`)
          .then((res) => {
            console.log(res);
            notification = res.data;
          })
          .catch((err) => {
          });
        const updatedNotifications = notifications.map(notif => notif._id === notification._id ? notification : notif);
        setNotifications(updatedNotifications);
      }
    } catch (err) {

    }
  };

  useEffect(() => {
    if (auth) {
      console.log('fetching notifications')
      const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/notifications?token=${Cookies.get('_auth')}`);

      eventSource.onmessage = (event) => {
        const notification = JSON.parse(event.data);
        if (!notificationIds.current.has(notification._id)) {
          console.log('Received notification:', notification)
          setNotifications(prevNotifications => [...prevNotifications, notification]);

          // Add the notification ID to the set of notification IDs
          notificationIds.current.add(notification._id);
        }
      };

      eventSource.onerror = (error) => {
      };

      // Close the EventSource connection when the component unmounts

    }
  }, [auth]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDS5UbPHEoKmTSGLtRLBzbSclyaV-lufcI"
      libraries={["places"]}
    >
      <Router>
        <ScrollToTop />
        <div className='min-vh-100'>
          <div className='sticky-top'>
            <MyNavbar role={role} isAuthenticated={auth} handleSignOut={handleSignOut} notifications={notifications} setNotifications={setNotifications} updateNotification={updateNotification} />
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blogs" element={<VoirBlogs />} />
            <Route path="/blog/:id" element={<VoirBlog />} />
            <Route path="/connection" element={<Connection SetIsAuthenticated={handleSignIn} />} />
            <Route path="/inscription" element={<Creercompte SetIsAuthenticated={handleSignIn} />} />
            <Route path="/connection/:path" element={<Connection SetIsAuthenticated={handleSignIn} />} />
            <Route path="/ajouterColis" element={<RequireAuth fallbackPath={`/connection/${"ajouterColis"}`}><RequireRole roles={['particulier']}><Ajouter_colis /></RequireRole></RequireAuth>} />
            <Route path="/voirColis" element={<VoirColisDispo />} />
            <Route path="/dashboard/*" element={<RequireAuth fallbackPath={`/connection/${"Dashboard"}`}><RequireRole roles={['admin', 'particulier', 'superadmin', 'transporteur']}><Dashboard /></RequireRole></RequireAuth>} />
            <Route path="/voirOffre" element={<RequireAuth fallbackPath={`/connection/${"voirOffre"}`}><RequireRole roles={['particulier', 'transporteur']}><VoirOffre /></RequireRole></RequireAuth>} />
            <Route path="/proposerTrajet" element={<RequireAuth fallbackPath={`/connection/${"porposerTrajet"}`}><RequireRole roles={['particulier', 'transporteur']}><ProposerTrajet /></RequireRole></RequireAuth>} />
            <Route path='unauthorized' element={<Unauthorized />} />
            <Route path='verify-email' element={<VerifyEmailToken />} />
            <Route path='payement' element={<Paiement />} />
            <Route path='/voirTrajets' element={<VoirTrajetsDispo />} />
            <Route path='gererCompte' element={<RequireAuth fallbackPath={`/connection/${"gererCompte"}`}><RequireRole roles={['transporteur', 'particulier']}><GererCompte handleSignOut={handleSignOut} /></RequireRole></RequireAuth>} />
            <Route path='suivi/:id' element={<RequireAuth fallbackPath={`/connection/${"suivi/:id"}`}><RequireRole roles={['admin', 'particulier']}><Suivi /></RequireRole></RequireAuth>} />
          </Routes>
        </div>
        <Footer />

      </Router >

    </LoadScript >
  );
};

export default App;
