import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import axios from "axios";

function VoirBlog() {
    const { id } = useParams();
    const [blog, setBlog] = useState({ content: "", card: { title: "", description: "", image: "" } });

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("fetching data");
                let url = `${process.env.REACT_APP_SERVER}/blogs/${id}`;
                const blogResponse = await axios.get(url);
                const blogData = blogResponse.data;
                console.log(blogData);
                setBlog(blogData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [id]);
    return (
        <div className="row">
            <div className="col-10 col-md-8 container ql-editor">
                <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
        </div>
    );
}
export default VoirBlog;