import { useEffect, useState } from "react";
import classes from "./ajouter-colis.module.css"
function Ajouter_colis_form({ coli, index, setColis }) {
    const [imageFiles, setImageFiles] = useState([]);
    const handleFileInputChange = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const newImageFiles = Array.from(files).map(file => ({
                file,
                imageUrl: URL.createObjectURL(file)
            }));
            const updatedImageFiles = [...imageFiles, ...newImageFiles];
            setImageFiles(updatedImageFiles);
            await setColis(prevColis => prevColis.map((coli, i) => i === index ? { ...coli, photos: updatedImageFiles } : coli));
        }

    };
    const handleInputChange = (event) => {
        let { name, value } = event.target;
        name = name.split("-")[0];
        if (name !== "title")
            value = Number(value);
        setColis(prevColis => prevColis.map((coli, i) => i === index ? { ...coli, [name]: value } : coli));
    }
    const handleRemoveImage = async (imageIndex) => {
        const updatedFiles = imageFiles.filter((_, index) => index !== imageIndex);
        setImageFiles(updatedFiles);
        await setColis(prevColis => prevColis.map((coli, i) => i === index ? { ...coli, photos: updatedFiles } : coli));
    };
    useEffect(() => {
        if (!coli) {
            setImageFiles([]);
            coli = {}
        }
        console.log(coli)
    }, [coli]);
    if (!coli) {
        return;
    }
    return (
        <div className={`${classes.FromBox} `}>
            <div>
                <div className="row">
                    <div className="col-3 d-flex flex-column">
                        <label htmlFor="" className="mb-auto">Quantité</label>
                        <input required name="qte" min="1" className={`form-control `} type="number" value={coli.qte || ''}
                            onChange={handleInputChange} />
                    </div>
                    <div className="col d-flex flex-column">
                        <label htmlFor="" className="mb-auto">Nom de l'object</label>
                        <input required name="title" className={`form-control`} type="text" value={coli.title || ''}
                            onChange={handleInputChange} />

                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col d-flex flex-column">
                        <label htmlFor="" className="mb-auto">Longueur (en cm)</label>
                        <input required name="length" className={`form-control `} type="number" value={coli.length || ''}
                            onChange={handleInputChange} />

                    </div>
                    <div className="col d-flex flex-column">
                        <label htmlFor="" className="mb-auto">Largeur (en cm)</label>
                        <input required name="width" className={`form-control `} type="number" value={coli.width || ''}
                            onChange={handleInputChange} />

                    </div>
                    <div className=" col d-flex flex-column">
                        <label htmlFor="" className="mb-auto">Hauteur (en cm)</label>
                        <input required name="height" className={`form-control `} type="number" value={coli.height || ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>
                <div>
                    <div className="row  mt-5">
                        <div className={`row m-0`}>
                            <input
                                className={`btn-check col-12 col-sm`}
                                type="radio"
                                required
                                name={`weight-${index}`}
                                value="1"
                                id={`-5kG-${index}`}
                                checked={coli.weight === 1}
                                onChange={handleInputChange} />
                            <label
                                className={`btn btn-outline-primary col-12 col-sm`}
                                htmlFor={`-5kG-${index}`}
                            >
                                -5KG
                            </label>

                            <input
                                className={`btn-check col-12 col-sm`}
                                type="radio"
                                name={`weight-${index}`} value="2"
                                checked={coli.weight === 2}

                                id={`5KG à 30KG-${index}`}
                                onChange={handleInputChange}
                            />
                            <label
                                className={`btn btn-outline-primary col-12 col-sm`}
                                htmlFor={`5KG à 30KG-${index}`}
                            >
                                5KG à 30KG
                            </label>

                            <input
                                className={`btn-check col-12 col-sm`}
                                type="radio"
                                name={`weight-${index}`} value="3"
                                checked={coli.weight === 3}

                                id={`30KG à 50KG-${index}`}
                                onChange={handleInputChange} />
                            <label
                                className={`btn btn-outline-primary col-12 col-sm`}
                                htmlFor={`30KG à 50KG-${index}`}
                            >
                                30KG à 50KG
                            </label>

                            <input
                                className={`btn-check col-12 col-sm`}
                                type="radio"
                                name={`weight-${index}`}
                                value="4"
                                checked={coli.weight === 4}

                                id={`50KG à 100KG-${index}`}
                                onChange={handleInputChange}
                            />
                            <label
                                className={`btn btn-outline-primary col-12 col-sm`}
                                htmlFor={`50KG à 100KG-${index}`}
                            >
                                50KG à 100KG
                            </label>

                            <input
                                className={`btn-check col-12 col-sm`}
                                type="radio"
                                name={`weight-${index}`}
                                value="5"
                                checked={coli.weight === 5}
                                id={`100KG-${index}`}
                                onChange={handleInputChange} />
                            <label
                                className={`btn btn-outline-primary col-12 col-sm `}
                                htmlFor={`100KG-${index}`}
                            >
                                +100KG
                            </label>
                            <div className="invalid-feedback col-12">
                                Choisissez le poids.
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row mt-4">
                        <div className="col-1">Photo</div>
                        <div className="col">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                    stroke="#0080FF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 8V13"
                                    stroke="#0080FF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M11.9945 16H12.0035"
                                    stroke="#0080FF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className={`${classes.attention}`}>
                        <p>
                            Mettez en valeur votre annonce ! Attirez l'attention
                            avec quelques photos montrant l'objet à transporter. Une
                            annonce avec photo est 7 fois plus consultée que sans
                            photo.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div>
                        <div className={`${classes.file_card} p-1 align-items-start`}>
                            <input id={`photo-${index}`} type="file" className={`d-none`} onChange={handleFileInputChange} multiple />
                            <label htmlFor={`photo-${index}`} className='row'>
                                < div className={`${classes.file_inputs} col-2 d-flex justify-content-center align-items-center`}>
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 50 50"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.77086 38.0209C6.02086 42.3126 8.33336 45.8334 14.0834 45.8334H35.9167C41.6667 45.8334 43.9584 42.3126 44.2292 38.0209L45.3125 20.8126C45.6042 16.3126 42.0209 12.5001 37.5 12.5001C36.2292 12.5001 35.0625 11.7709 34.4792 10.6459L32.9792 7.62508C32.0209 5.72925 29.5209 4.16675 27.3959 4.16675H22.625C20.4792 4.16675 17.9792 5.72925 17.0209 7.62508L15.5209 10.6459C14.9375 11.7709 13.7709 12.5001 12.5 12.5001C7.97919 12.5001 4.39586 16.3126 4.68752 20.8126L5.22919 29.2917"
                                            stroke="black"
                                            strokeOpacity="0.88"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21.875 16.6667H28.125"
                                            stroke="black"
                                            strokeOpacity="0.88"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M25.0003 37.4999C28.7295 37.4999 31.7712 34.4583 31.7712 30.7291C31.7712 26.9999 28.7295 23.9583 25.0003 23.9583C21.2712 23.9583 18.2295 26.9999 18.2295 30.7291C18.2295 34.4583 21.2712 37.4999 25.0003 37.4999Z"
                                            stroke="black"
                                            strokeOpacity="0.88"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className='col'>
                                    <p className={`${classes.cliquez}`}>Cliquez ici pour sélectionner vos fichiers ou glissez-les dans cette zone</p>
                                    <p className={`${classes.JPG}`} >Nous acceptons les documents JPG, PNG et GIF jusqu'à 7 MB.
                                    </p>
                                </div >
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='d-flex flex-wrap mt-2'>
                        {coli.photos.map((image, imageIndex) => (
                            <div key={imageIndex} className='position-relative m-2'>
                                <img src={image.imageUrl} alt="Uploaded" className='rounded-3' style={{ maxWidth: '100px', marginRight: '10px' }} />
                                <span onClick={() => handleRemoveImage(imageIndex)} className="position-absolute top-0 start-100 translate-middle ">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="black" fillOpacity="0.88" />
                                        <path d="M13.0604 12.0004L15.3604 9.70035C15.6504 9.41035 15.6504 8.93035 15.3604 8.64035C15.0704 8.35035 14.5904 8.35035 14.3004 8.64035L12.0004 10.9404L9.70035 8.64035C9.41035 8.35035 8.93035 8.35035 8.64035 8.64035C8.35035 8.93035 8.35035 9.41035 8.64035 9.70035L10.9404 12.0004L8.64035 14.3004C8.35035 14.5904 8.35035 15.0704 8.64035 15.3604C8.79035 15.5104 8.98035 15.5804 9.17035 15.5804C9.36035 15.5804 9.55035 15.5104 9.70035 15.3604L12.0004 13.0604L14.3004 15.3604C14.4504 15.5104 14.6404 15.5804 14.8304 15.5804C15.0204 15.5804 15.2104 15.5104 15.3604 15.3604C15.6504 15.0704 15.6504 14.5904 15.3604 14.3004L13.0604 12.0004Z" fill="black" fillOpacity="0.88" />
                                    </svg>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>

    )
}
export default Ajouter_colis_form;