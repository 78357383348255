import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import ChatBox from "../components/chatbox/chatbox";
import classes from "./style.module.css"
import { ca } from "date-fns/locale";

function MesLivraisons() {
    const [trajets, setTrajets] = useState([]);
    const [ttrajets, setTtrajets] = useState([]);
    const [rotatedArrows1, setRotatedArrows1] = useState({});

    const handleToggle1 = (index) => {
        setRotatedArrows1((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const columns = [
        ["date de départ", "go_time"],
        ["addresse de départ", "from_address.name"],
        ["date d'arrivé", "arrive_time"],
        ["addresse d'arrivé", "to_address.name"],
        ["prix", "offre.prix"],
        ["etat", "etat"],
        ["Operations", "operations"]
    ];
    const colisCols = [
        ["qte", "qte"],
        ["image", 'photos'],
        ["title", "title"],
        ["width", "width"],
        ["height", "height"],
        ["length", "length"],
        ["weight", "weight"]
    ]
    const [selectedTrajet, setSelectedTrajet] = useState(null);

    useEffect(() => {
        const fetchData2 = async () => {
            try {
                const trajetOffreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres/livre`);
                const trajetOffreData = trajetOffreResponse.data;
                const promises = trajetOffreData.map(async (TrajetOffreItem) => {
                    try {
                        const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${TrajetOffreItem.ttrajet}`);
                        const trajet = trajetResponse.data;
                        trajet.colis = [];
                        for (let coli of TrajetOffreItem.colis) {
                            await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                                .then((res) => {
                                    trajet.colis.push(res.data)
                                })
                        }
                        trajet.offre = TrajetOffreItem;
                        return trajet;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null; // Returning null to indicate failure
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log(filteredTrajetsData)
                setTtrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        const fetchData1 = async () => {
            try {
                const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/livre`);
                const trajetData = trajetResponse.data;

                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        const offreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/offres/livre?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        TrajetItem.offre = offreResponse.data ?? [];
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null; // Returning null to indicate failure
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log(filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData1();
        fetchData2();
    }, []);
    // This function will redirect to another page
    let watchId;

    async function updateRow(index) {
        try {
            const id = trajets[index]._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/offres/deliver/${id}`)
                .then((response) => {
                    trajets[index] = response.data;
                })
            if (watchId !== undefined) {
                // If we're already watching the position, stop watching it
                navigator.geolocation.clearWatch(watchId);
            }

            watchId = navigator.geolocation.watchPosition(
                async function (position) {
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);

                    const trackingData = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    try {
                        const response = await axios.post(`${process.env.REACT_APP_SERVER}/tracking`, trackingData);
                        console.log(response.data);
                    } catch (error) {
                        console.error("Error:", error);
                    }


                },
                function (error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                }
            );
        }
        catch (error) {
            console.error("Error updating data:", error);
        }
    }
    async function updateRow1(index) {
        try {
            const id = ttrajets[index].offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/deliver/${id}`)
                .then((response) => {
                    trajets[index].etat = "en cours";
                })
            if (watchId !== undefined) {
                // If we're already watching the position, stop watching it
                navigator.geolocation.clearWatch(watchId);
            }

            watchId = navigator.geolocation.watchPosition(
                async function (position) {
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);

                    const trackingData = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    try {
                        const response = await axios.post(`${process.env.REACT_APP_SERVER}/tracking`, trackingData);
                        console.log(response.data);
                    } catch (error) {
                        console.error("Error:", error);
                    }


                },
                function (error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }
    const handleRowClick = (index) => {
        // Open the chat box with the user associated with the row
        const row = trajets[index];
        console.log(row._id);
        setSelectedTrajet(row._id);
    };

    return (
        <Row style={{ maxWidth: "100%" }} >
            <Card className="Card overflow-auto" style={{ maxWidth: "100%" }}>
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Mes livraisons</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody className="accordion" id="accordion1">
                                {trajets.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr >
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                    {column[1] === "from_address.name" ? (
                                                        row.from_address.name ? row.from_address.name : "Introuvable"
                                                    ) : column[1] === "to_address.name" ? (
                                                        row.to_address.name ? row.to_address.name : "Introuvable"
                                                    ) : column[1] === "go_time" ? (
                                                        new Date(row.go_time).toLocaleDateString('fr-FR')
                                                    ) : column[1] === "arrive_time" ? (
                                                        new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                    ) : column[1] === "offre.prix" ? (
                                                        row.offre.prix ? row.offre.prix : "Introuvable"
                                                    ) : column[1] === "operations" ? (
                                                        <div className="row m-0">
                                                            <button className="btn btn-outline-success btn-sm my-1" onClick={() => updateRow(index)}>Delivrer</button>
                                                            <button className="btn btn-outline-primary btn-sm my-1" onClick={() => handleRowClick(index)}>Discuter</button>
                                                        </div>
                                                    ) : (
                                                        row[column[1]] || "Introuvable"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td
                                                onClick={() => handleToggle1(index)}
                                                colSpan={columns.length}
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#accordionContent1${index}`}
                                                aria-expanded="true"
                                                aria-controls={`accordionContent1${index}`}
                                                className={`m-0 pt-0 border-bottom-0 text-center ${rotatedArrows1[index] ? 'arrow-rotated' : ''}`}
                                            >
                                                <div className="text-center position-relative p-1" style={{ height: '0px' }}>
                                                    <div style={{ borderTop: '1px solid #dee2e6' }}></div>
                                                    <svg
                                                        className={`position-absolute start-50 translate-middle `}
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                            fill="#999"
                                                        />
                                                        <path
                                                            className={`${classes.arrow_path} ${rotatedArrows1[index] ? classes.arrow_rotated : ''}`} // Rotate the arrow only if it's toggled
                                                            d="M15.5299 11.97C15.2399 11.68 14.7599 11.68 14.4699 11.97L12.7499 13.69V8.5C12.7499 8.09 12.4099 7.75 11.9999 7.75C11.5899 7.75 11.2499 8.09 11.2499 8.5V13.69L9.52994 11.97C9.23994 11.68 8.75994 11.68 8.46994 11.97C8.17994 12.26 8.17994 12.74 8.46994 13.03L11.4699 16.03C11.6199 16.18 11.8099 16.25 11.9999 16.25C12.1899 16.25 12.3799 16.18 12.5299 16.03L15.5299 13.03C15.8199 12.74 15.8199 12.26 15.5299 11.97Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr id={`accordionContent1${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                            <td colSpan={columns.length} >
                                                <Table>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {row.colis.map((colis) => (
                                                            <tr key={colis._id}>
                                                                {colisCols.map(column => (
                                                                    <td key={column[1]} >
                                                                        {column[1] === "photos" ? (
                                                                            <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                        ) : (
                                                                            colis[column[1]] || "Introuvable"
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                                {ttrajets.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr >
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                    {column[1] === "from_address.name" ? (
                                                        row.from_address.name ? row.from_address.name : "Introuvable"
                                                    ) : column[1] === "to_address.name" ? (
                                                        row.to_address.name ? row.to_address.name : "Introuvable"
                                                    ) : column[1] === "go_time" ? (
                                                        new Date(row.go_time).toLocaleDateString('fr-FR')
                                                    ) : column[1] === "arrive_time" ? (
                                                        new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                    ) : column[1] === "offre.prix" ? (
                                                        row.offre.prix ? row.offre.prix : "Introuvable"
                                                    ) : column[1] === "etat" ? (
                                                        row.offre.prix ? row.offre.etat : "Introuvable"
                                                    ) : column[1] === "operations" ? (
                                                        <div className="row m-0">
                                                            <button className="btn btn-outline-success btn-sm my-1" onClick={() => updateRow1(index)}>Delivrer</button>
                                                            <button className="btn btn-outline-primary btn-sm my-1" onClick={() => handleRowClick(index)}>Discuter</button>
                                                        </div>
                                                    ) : (
                                                        row[column[1]] || "Introuvable"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td
                                                onClick={() => handleToggle1(index)}
                                                colSpan={columns.length}
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#accordionContent1${index}`}
                                                aria-expanded="true"
                                                aria-controls={`accordionContent1${index}`}
                                                className={`m-0 pt-0 border-bottom-0 text-center ${rotatedArrows1[index] ? 'arrow-rotated' : ''}`}
                                            >
                                                <div className="text-center position-relative p-1" style={{ height: '0px' }}>
                                                    <div style={{ borderTop: '1px solid #dee2e6' }}></div>
                                                    <svg
                                                        className={`position-absolute start-50 translate-middle `}
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                            fill="#999"
                                                        />
                                                        <path
                                                            className={`${classes.arrow_path} ${rotatedArrows1[index] ? classes.arrow_rotated : ''}`} // Rotate the arrow only if it's toggled
                                                            d="M15.5299 11.97C15.2399 11.68 14.7599 11.68 14.4699 11.97L12.7499 13.69V8.5C12.7499 8.09 12.4099 7.75 11.9999 7.75C11.5899 7.75 11.2499 8.09 11.2499 8.5V13.69L9.52994 11.97C9.23994 11.68 8.75994 11.68 8.46994 11.97C8.17994 12.26 8.17994 12.74 8.46994 13.03L11.4699 16.03C11.6199 16.18 11.8099 16.25 11.9999 16.25C12.1899 16.25 12.3799 16.18 12.5299 16.03L15.5299 13.03C15.8199 12.74 15.8199 12.26 15.5299 11.97Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr id={`accordionContent1${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                            <td colSpan={columns.length} >
                                                <Table>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {row.colis.map((colis) => (
                                                            <tr key={colis._id}>
                                                                {colisCols.map(column => (
                                                                    <td key={column[1]} >
                                                                        {column[1] === "photos" ? (
                                                                            <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                        ) : (
                                                                            colis[column[1]] || "Introuvable"
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
            {selectedTrajet && <ChatBox selectedTrajet={selectedTrajet} />}

        </Row>
    );
}

export default MesLivraisons;
