import { useEffect, useState } from "react";
import classes from "./ajouter-colis.module.css"
import axios from "axios";
function Ajouter_offre_form({ trajet, setTrajet }) {
    const [percentage, setPercentage] = useState(0)
    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_SERVER}/tarif`).then((res) => {
                    setPercentage(res.data.value / 100);
                }).catch((err) => { console.log(err) })
            } catch (err) {
                console.log(err)
            }
        }

        fetchData();
    }, []);
    const [prix, setPrix] = useState(0)
    return (
        <div className={`${classes.FromBox}`}>
            <div className="row">
                <div className="col">
                    <label htmlFor="">Prix éstimé</label>
                    <input className="form-control"
                        required type="number" min="1" onChange={(e) => {
                            setPrix(e.target.value);
                            setTrajet({ ...trajet, prix: Number(e.target.value) })
                        }}
                    />
                </div>
                <div className="col">
                    <label>Prix finale</label>
                    <input
                        value={(prix ?? 0) * (1 + percentage)}
                        className="form-control" disabled type="number" onChange={(e) => setTrajet({ ...trajet, prix: Number(e.target.value) })} />
                </div>
            </div>
        </div>
    )
}
export default Ajouter_offre_form