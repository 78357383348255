import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

function RequireRole({ children, roles }) {
    const navigate = useNavigate();
    const user = useAuthUser()
    const userRole = user.userRole

    useEffect(() => {
        if (!roles.includes(userRole)) {
            console.log(user)
            navigate('/unauthorized');
        }
    }, [userRole, navigate, roles]);

    return roles.includes(userRole) ? children : null;
}

export default RequireRole;