import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import { Relaisinput } from "../../../components/map/input";

function Relais() {
    const [relais, setRelais] = useState([]);
    const [addresse, setAddresse] = useState({})
    const columns = [
        ["date de création", "date_creation"],
        ["nom", "name"],
        ["addresse", "address"],
        ["opération", "operations"]
    ];
    const fetchData = async () => {
        try {
            console.log("fetching data")
            const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/relais`);
            const trajetData = trajetResponse.data;
            console.log(trajetData)


            const filteredrelaisData = trajetData.filter(item => item !== null);
            console.log(filteredrelaisData)
            setRelais(filteredrelaisData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    const handlePlaceSelection = (place) => {
        setAddresse(place);
        console.log(place)
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(addresse)
        try {
            await axios.post(`${process.env.REACT_APP_SERVER}/relais`, addresse).then((response) => {
                if (response.status === 201) {
                    fetchData()
                    console.log("Relais ajouté avec succès")
                }
            }).catch((error) => {
                console.log("Error: " + error)

            })

        } catch (error) {
            console.log(error);
        }
    }
    const handleDelete = async (id) => {
        try {
            console.log(`Deleting relais ${id}`);
            const response = await axios.delete(`${process.env.REACT_APP_SERVER}/relais/${id}`);
            if (response.status === 200) {
                fetchData()
                console.log("Relais supprimé avec succès")
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Row style={{ maxWidth: "100%" }} >
            <div className="mb-3 mt-1 w-100 p-0">
                <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Ajouter un relais
                </button>
                <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <div className="w-75">
                                    <Relaisinput onChange={place => handlePlaceSelection(place)} placeholder={"Saisir une addresse"} />
                                </div>
                                <button type="submit" className="btn btn-outline-primary w-25 text-nowrap">Ajouter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Card className="Card" >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Liste des relais</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {relais.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent${index}`} aria-expanded="false" aria-controls={`accordionContent${index}`}>
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle">
                                                    {column[1] === "operations" ? (
                                                        <div className="row m-0">

                                                            <button type="button" className="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                Supprimer
                                                            </button>


                                                            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            ...
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary " data-bs-dismiss="modal">Annuler</button>
                                                                            <button type="button" className="btn btn-outline-danger text-nowrap" data-bs-dismiss="modal" onClick={() => handleDelete(row._id)}>Supprimer</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : column[1] === "date_creation" ? (
                                                        new Date(row[column[1]]).toLocaleDateString('fr-FR', {
                                                            month: 'long',
                                                            day: 'numeric',
                                                            year: 'numeric'
                                                        })
                                                    ) : (
                                                        row[column[1]] || "Introuvable"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </Row>
    );
}

export default Relais;
