import { useEffect, useState } from "react";
import classes from "./ajouter-colis.module.css"
import axios from "axios";
import Mapinput from "../../components/map/input";
import { RelaiMapSelection } from "../../components/map/map";
import { ca } from "date-fns/locale";
function Ajouter_trajet_form({ trajet, setTrajet, offre, setOffre }) {
    const handlePlaceSelection = (place, addressType) => {
        setTrajet({ ...trajet, [addressType]: place });
        console.log(place)
    };
    const handleRelaiSelection1 = (place) => {
        console.log("Setting relai1");
        setOffre({ ...offre, relai1: place })
    };
    const handleRelaiSelection2 = (place) => {
        console.log("Setting relai2");
        setOffre({ ...offre, relai2: place })
    };
    const handleReset = () => {
        let t = {
            "from_address": trajet.from_address,
            "to_address": trajet.to_address,
            "go_time": trajet.go_time,
            "arrive_time": trajet.arrive_time,
        }
        let offre = {}
        setOffre(offre)
        setTrajet(t)
    }
    const weightList = ["-5KG", "entre 5KG et 30KG", "entre 30KG et 50KG", "entre 50KG et 100KG", "+100KG"];
    const [selectedTrajet, setSelectedTrajet] = useState();
    const [trajets, setTrajets] = useState([])
    const [relai1, Setrelai1] = useState(false);
    const [relai2, SetRelai2] = useState(false);

    const searchTrajets = async () => {
        try {
            const filter = trajet
            await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/getByFilter`, { params: filter }).then((res) => {
                console.log(res.data)
                setTrajets(res.data)
            }).catch((err) => {
                console.log(err)
            });
        }
        catch (err) {
            console.log(err)
        }
    }
    const getRelaiName = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data.address
            }).catch((err) => {
                console.log(err)
            });
            return address
        }
        catch (err) {
            console.log(err)
        }
    }
    const updateTrajet = async (t) => {
        console.log(t)
        setSelectedTrajet(t)
        const n1 = await getRelaiName(t.relai1)
        const n2 = await getRelaiName(t.relai2)
        console.log(n1, n2)
        setTrajet({ ...t, relai1: n1, relai2: n2 })

    }
    useEffect(() => {
        const { from_address: from_address_trajet, to_address: to_address_trajet, go_time: go_time_trajet, arrive_time: arrive_time_trajet } = trajet;

        if (selectedTrajet) {
            const { from_address: from_address_selected, to_address: to_address_selected, go_time: go_time_selected, arrive_time: arrive_time_selected } = selectedTrajet;

            if (
                from_address_trajet?.name !== from_address_selected?.name ||
                to_address_trajet?.name !== to_address_selected?.name ||
                go_time_trajet !== go_time_selected ||
                arrive_time_trajet !== arrive_time_selected
            ) {
                const newTrajet = {
                    from_address: from_address_trajet,
                    to_address: to_address_trajet,
                    go_time: go_time_trajet,
                    arrive_time: arrive_time_trajet
                };

                // Only update state if the newTrajet is different from the current trajet
                if (
                    newTrajet.from_address?.name !== trajet.from_address?.name ||
                    newTrajet.to_address?.name !== trajet.to_address?.name ||
                    newTrajet.go_time !== trajet.go_time ||
                    newTrajet.arrive_time !== trajet.arrive_time
                ) {
                    setTrajet(newTrajet);
                }
            }
        }
    }, [trajet, selectedTrajet]);
    return (
        <div className={`${classes.FromBox} `}>
            <div className=" ">
                <div className="row mt-3">
                    <Mapinput firstValue={trajet?.from_address?.name || ""} onChange={place => handlePlaceSelection(place, 'from_address')} placeholder={"Ville de départ"} />
                </div>
                <div className="row mt-4">
                    <Mapinput firstValue={trajet?.to_address?.name || ""} onChange={place => handlePlaceSelection(place, 'to_address')} placeholder={"Ville d'arrivée"} />
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <input
                            className="form-control" type="date" required
                            value={trajet.go_time ? new Date(trajet.go_time).toISOString().split('T')[0] : ''}
                            onChange={(e) => setTrajet({ ...trajet, go_time: e.target.value })}
                        />
                    </div>
                    <div className="col">
                        <input
                            value={trajet.arrive_time ? new Date(trajet.arrive_time).toISOString().split('T')[0] : ''}
                            className="form-control" type="date" required
                            onChange={(e) => setTrajet({ ...trajet, arrive_time: e.target.value })}
                        />
                    </div>
                </div>
                <div className="row mt-4 p-2">
                    <button type="button" onClick={searchTrajets} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal1">
                        Chercher les trajets disponibles
                    </button>
                </div>

                <div className="modal fade" id="modal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Trajets disponibles</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {trajets.length > 0 ? (
                                    <ul className="list-group">
                                        {trajets.map((trajet, index) => (
                                            <li data-bs-dismiss="modal" key={index}
                                                data-bs-target="#modalToggle" data-bs-toggle="modal"
                                                onClick={() => { updateTrajet(trajet) }}
                                                className="list-group-item list-group-item-action">
                                                <table className="table m-0">
                                                    <tr>
                                                        <td className="">
                                                            <h6 className="bg-transparent">{trajet.from_address.name}</h6>
                                                        </td>
                                                        <td>
                                                            <h6 className="bg-transparent">{new Date(trajet.go_time).toLocaleDateString()}</h6>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <h6 className="bg-transparent">{trajet.to_address.name} </h6>
                                                        </td>
                                                        <td>
                                                            <h6 className="bg-transparent">{new Date(trajet.arrive_time).toLocaleDateString()}</h6>
                                                        </td>
                                                    </tr>

                                                </table>

                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No trajets available.</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Annuler</button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-1 me-4">
                        <p>Attention</p>
                    </div>
                    <div className="col">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="#0080FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 8V13"
                                stroke="#0080FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.9945 16H12.0035"
                                stroke="#0080FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <p className={`${classes.attention}`}>
                        Tous les colis à expédier peuvent être créés sur cette
                        page et seront livrés à votre adresse selon votre trajet
                        choisi ici
                    </p>{" "}
                </div>
            </div>

            <div className="modal fade modal-xl" id="modalToggle" aria-hidden="true" aria-labelledby="modalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                        <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                            <h1 className="modal-title fs-5 text-light" id="modalToggleLabel">Faire une offre</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col">
                                    <div className="card rounded-4">
                                        <div className="card-body">
                                            <h5 className="card-title">Détails d'offre</h5>
                                            <h6 className="fw-bold">DÉPART :</h6>
                                            <p>
                                                Récupérer main à main : {trajet && trajet.mam1 === true ? "Oui" : "Non"}
                                            </p>
                                            <p>
                                                Récupérer en point relais : <b>{(trajet && trajet.relai1) ? (trajet.relai1) : ("Non")}</b>
                                            </p>
                                            <h6 className="fw-bold">ARRIVÉ :</h6>
                                            <p>
                                                Déposer main à main : {trajet && trajet.mam2 === true ? "Oui" : "Non"}
                                            </p>
                                            <p>
                                                Déposer en point relais : <b>{(trajet && trajet.relai2) ? (trajet.relai2) : ("Non")}</b>
                                            </p>
                                            <h6 className="fw-bold">CONTRAINTES :</h6>
                                            <p>
                                                Dimentions MAX : {trajet && trajet.max_length && trajet.max_width && trajet.max_height ? (trajet.max_length + " x " + trajet.max_width + " x " + trajet.max_height + " cm") : ("Non spécifique")}
                                            </p>
                                            <p>
                                                Poids MAX : {trajet && trajet.max_weight ? (weightList[trajet.max_weight]) : ("Non spécifique")}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className="card rounded-4 mt-4 mt-lg-0">
                                        <div className="card-body">
                                            <h5 className="card-title" >Votre Offre</h5>
                                            <fieldset>
                                                <h6 className="fw-bold">DÉPART :</h6>
                                                <div className="d-flex">
                                                    <p className="me-4 text-nowrap">Récupérer main à main :</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam1" // Separate name for this group
                                                            id="r1"
                                                            value="oui"
                                                            onChange={(event) => {
                                                                //handleInputChange(event);
                                                                setOffre({ ...offre, mam1: true });
                                                                // Ensure relai1 is false when "main à main" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r1">Oui</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam1" // Separate name for this group
                                                            id="r2"
                                                            value="non"
                                                            onChange={(event) => {
                                                                //handleInputChange(event);
                                                                setOffre({ ...offre, mam1: false });
                                                                // Ensure relai1 is false when "main à main" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r2">Non</label>
                                                    </div>
                                                </div>

                                                <div className="d-flex">
                                                    <p className="me-4 text-nowrap">Récupérer en point relais :</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai1" // Separate name for this group
                                                            id="r3"
                                                            value="oui"
                                                            onChange={(event) => {
                                                                // handleInputChange(event);
                                                                Setrelai1(true);
                                                                setOffre({ ...offre, mam1: false }); // Ensure mam1 is false when "relai" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r3">Oui</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai1"
                                                            id="r4"
                                                            value="non"
                                                            onChange={(event) => {
                                                                //  handleInputChange(event);
                                                                Setrelai1(false);
                                                                setOffre({ ...offre, relai1: undefined });
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r4">Non</label>
                                                    </div>
                                                </div>
                                                {relai1 === true && (
                                                    <button
                                                        className="btn btn-outline-primary col-12 mb-4"
                                                        data-bs-target="#modalToggle2" data-bs-toggle="modal">
                                                        Selectionner une point relais
                                                    </button>
                                                )}

                                            </fieldset>

                                            <fieldset className="">
                                                <h6 className="fw-bold">ARRIVÉ :</h6>
                                                <div className="d-flex">
                                                    <p className="me-4">Déposer main à main :</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam2" // Separate name for this group
                                                            id="r5"
                                                            value="oui"
                                                            onChange={(event) => {
                                                                //  handleInputChange(event);
                                                                setOffre({ ...offre, mam2: true });
                                                                // Ensure relai2 is false when "main à main" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r5">Oui</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="mam2"
                                                            id="r6"
                                                            value="non"
                                                            onChange={(event) => {
                                                                // handleInputChange(event);
                                                                setOffre({ ...offre, mam2: false });
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r6">Non</label>
                                                    </div>
                                                </div>

                                                <div className="d-flex">
                                                    <p className="me-4">Déposer en point relais :</p>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai2" // Separate name for this group
                                                            id="r7"
                                                            value="oui"
                                                            onChange={(event) => {
                                                                // handleInputChange(event);
                                                                SetRelai2(true);
                                                                setOffre({ ...offre, mam2: false }); // Ensure mam2 is false when "relai" is selected
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r7">Oui</label>
                                                    </div>
                                                    <div className="form-check me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="relai2"
                                                            id="r8"
                                                            value="non"
                                                            onChange={(event) => {
                                                                // handleInputChange(event);
                                                                SetRelai2(false);
                                                                setOffre({ ...offre, relai2: undefined });
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="r8">Non</label>
                                                    </div>
                                                </div>
                                                {relai2 === true && (
                                                    <button className="btn btn-outline-primary col-12" data-bs-target="#modalToggle3" data-bs-toggle="modal">Selectionner une point relais</button>
                                                )}
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={handleReset}>Annuler</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade modal-xl" id="modalToggle2" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                            <button type="button" className="btn-close" data-bs-target="#modalToggle" data-bs-toggle="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ height: "70vh" }}>
                            <RelaiMapSelection setRelai={handleRelaiSelection1} nextModalId={"modalToggle"} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-target="#modalToggle" data-bs-toggle="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade modal-xl" id="modalToggle3" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                            <button type="button" className="btn-close" data-bs-target="#modalToggle" data-bs-toggle="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ height: "70vh" }}>
                            <RelaiMapSelection setRelai={handleRelaiSelection2} nextModalId={"modalToggle"} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-target="#modalToggle" data-bs-toggle="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Ajouter_trajet_form