import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import { ca } from "date-fns/locale";

function Sliders() {
    const [sliders, setSliders] = useState([]);
    const [imageFile, setImageFile] = useState([]); // Initialize imageFile as an empty array
    const columns = [
        ["date de création", "date_creation"],
        ["chemin", "path"],
        ["photo", "photo"],
        ["opération", "operations"]
    ];
    const handleFileInputChange = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            const newImageFile = {
                file,
                imageUrl: URL.createObjectURL(file)
            };
            const updatedImageFile = [...imageFile, newImageFile];
            setImageFile(updatedImageFile);
        }
    };
    const fetchData = async () => {
        try {
            console.log("fetching data")
            const slidersResponse = await axios.get(`${process.env.REACT_APP_SERVER}/sliders`);
            const slidersData = slidersResponse.data;
            console.log(slidersData)


            const filteredslidersData = slidersData.filter(item => item !== null);
            console.log(filteredslidersData)
            setSliders(filteredslidersData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log(imageFile);
            let formData = new FormData();
            formData.append('file', imageFile[0].file);

            try {
                const res1 = await axios.post(`${process.env.REACT_APP_SERVER}/sliders`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                console.log(res1.data);
                if (res1.status === 200) {
                    setImageFile([]); // Reset imageFile to an empty array
                    fetchData()
                    console.log("Sliders ajouté avec succès")
                }
            } catch (err) {
                console.error("ERROR", err.message, imageFile);
                throw err; // If an error occurs, throw it to be caught in the outer catch block
            }


        } catch (error) {
            console.log(error);
        }
    }
    const handleCheckboxChange = async (index) => {
        try {
            const row = sliders[index]
            console.log(row)
            row.active = !row.active
            await axios.put(`${process.env.REACT_APP_SERVER}/sliders/${row._id}`, { active: row.active }).then((response) => {
                fetchData()
            })
        } catch (err) {
            console.log(err)
        }
    }
    const handleDelete = async (id) => {
        try {
            console.log(`Deleting sliders ${id}`);
            const response = await axios.delete(`${process.env.REACT_APP_SERVER}/sliders/${id}`);
            if (response.status === 200) {
                fetchData()
                console.log("Sliders supprimé avec succès")
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Row style={{ maxWidth: "100%" }} >
            <div className="mb-3 mt-1 w-100 p-0">
                <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Ajouter un sliders
                </button>
                <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <div className="w-75">
                                    <input id={`photo`} className="form-control" type="file" onChange={handleFileInputChange} />
                                </div>
                                <button type="submit" className="btn btn-outline-primary w-25 text-nowrap">Ajouter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Card className="Card" style={{ maxWidth: "100%" }}  >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Liste des sliders</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {sliders.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent${index}`} aria-expanded="false" aria-controls={`accordionContent${index}`}>
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle">
                                                    {column[1] === "operations" ? (
                                                        <div className="d-flex align-items-center">
                                                            <button className="btn btn-outline-danger col-7 me-4" onClick={() => handleDelete(row._id)}>Supprimer</button>
                                                            <div className="form-check form-switch ">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id={`flexSwitchCheckChecked-${row._id}`}
                                                                    checked={row.active}
                                                                    onChange={() => handleCheckboxChange(index)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${row.id}`}>
                                                                    {row.active ? 'Activé' : 'Désactivé'}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ) : column[1] === "photo" ? (
                                                        <div>
                                                            <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(row.path)}`} style={{ maxHeight: "40px" }} alt="Coli Image" />
                                                        </div>
                                                    ) : column[1] === "date_creation" ? (
                                                        new Date(row[column[1]]).toLocaleDateString('fr-FR', {
                                                            month: 'long',
                                                            day: 'numeric',
                                                            year: 'numeric'
                                                        })
                                                    ) : (
                                                        row[column[1]] || "Introuvable"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </Row>
    );
}
export default Sliders;
