import React, { useEffect, useState } from "react";

import InscriptionImg from "../../assets/home_inscription.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { ca } from "date-fns/locale";
const GererCompte = ({ handleSignOut }) => {
    const [psw, setPsw] = useState()
    const handlepswcheck = () => {

        if ((!psw && !formData.newPassword) || psw === formData.newPassword) {
            return true
        }
        else {
            return false
        }
    }
    const [formData, setFormData] = React.useState({ newPassword: null })
    const onSubmit = (e) => {
        console.log(formData);

        e.preventDefault()
        if (handlepswcheck() && formData.password) {
            console.log(formData);
            axios.put(`${process.env.REACT_APP_SERVER}/users`, formData)

                .catch((error) => {
                    if (error.response.status === 400)
                        console.log(error.response.status);
                })
        }
    }
    const navigate = useNavigate()
    const deleteUser = (e) => {
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_SERVER}/users`)
            .then((res) => {
                handleSignOut();
                navigate("/")
            })
            .catch((error) => {

                console.log(error.message);
            })
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_SERVER}/users`);
                setFormData(res.data);
                console.log(res.data)
            }

            catch (error) { }
        };

        fetchData();
    }, []);
    const [isModalOpen, setIsModalOpen] = useState(false); // Assume modal is open initially

    return (
        <div className="bg-gradient-primary">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex">
                                        <div className="flex-grow-1 bg-login-image ">
                                            <img src={InscriptionImg} className="w-100 " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">Modifier votre compte</h4>
                                            </div>
                                            <form className="user" onSubmit={onSubmit}>
                                                <div className="row mb-3">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input className="form-control form-control-user" type="text" id="exampleFirstName" placeholder="Prénom" name="first_name"
                                                            value={formData.prenom}
                                                            onChange={(e) => setFormData({ ...formData, prenom: e.target.value })} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input className="form-control form-control-user" type="text" id="exampleLastName" placeholder="Nom" name="last_name"
                                                            value={formData.nom}
                                                            onChange={(e) => setFormData({ ...formData, nom: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="mb-3"><input className="form-control form-control-user" type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Saisir votre adresse Email" name="email"
                                                    value={formData.email}
                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                                </div>
                                                <div className="mb-3">
                                                    <input className="form-control " type="password" placeholder="Saisir votre ancien mot de passe"
                                                        onChange={(e) => setFormData({ ...formData, password: e.target.value })} required />
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input className="form-control form-control-user" type="password" id="exampleFirstName" placeholder="Mot de passe" name="password"
                                                            onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })} /></div>
                                                    <div className="col-sm-6">
                                                        <input className="form-control form-control-user" type="password" placeholder="Répeter mots de passe" name="repeat_password"
                                                            onChange={(e) => setPsw(e.target.value)} />
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary d-block btn-user w-100 " type="submit">Modifier</button>
                                                <hr />
                                                <button className="btn btn-outline-danger d-block btn-user w-100 " href="/" onClick={() => {
                                                    setIsModalOpen(true)
                                                }}>Supprimer votre compte</button>
                                                <Modal isOpen={isModalOpen}>
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h1 className="modal-title fs-5" id="exampleModalLabel">Supprimer Compte</h1>
                                                            <button type="button" className="btn-close" onClick={() => { setIsModalOpen(false) }}></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>êtes-vous sûr</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Annuler</button>
                                                            <button type="button" className="btn btn-outline-danger text-nowrap" data-bs-dismiss="modal" onClick={deleteUser}>Supprimer</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

};

export default GererCompte;