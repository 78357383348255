import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios, { AxiosHeaders } from "axios";
import { useNavigate } from "react-router-dom";

function Paiement() {
    const makePayment = async () => {
        const data = {
            "receiverWalletId": "6642a73403193eab8b96cdb8",
            "token": "TND",
            "amount": 10000,
            "type": "immediate",
            "description": "payment description",
            "acceptedPaymentMethods": [
                "wallet",
                "bank_card",
                "e-DINAR"
            ],
            "lifespan": 10,
            "checkoutForm": true,
            "addPaymentFeesToAmount": true,
            "firstName": "John",
            "lastName": "Doe",
            "phoneNumber": "22777777",
            "email": "john.doe@gmail.com",
            "orderId": "1234657",
            "webhook": "https://merchant.tech/api/notification_payment",
            "silentWebhook": true,
            "successUrl": "https://gateway.sandbox.konnect.network/payment-success",
            "failUrl": "https://gateway.sandbox.konnect.network/payment-failure",
            "theme": "light"
        }
        await axios.post("https://api.preprod.konnect.network/api/v2/payments/init-payment", data, {
            headers: {
                "x-api-key": "6642a73403193eab8b96cdb4:HNzxdFf0SgGvutD8"
            }
        }).then((res) => {
            console.log(res.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }
    return (
        <Row>
            <Card className="Card" style={{ minWidth: "600px" }}>
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Paiement</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">

                    <div className="form">
                        <div className="row">
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                <label htmlFor="floatingInput">Nom</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                <label htmlFor="floatingInput">Prenom</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                <label htmlFor="floatingInput">Email address</label>
                            </div>
                            <button onClick={makePayment}>Payer</button>
                        </div>
                    </div>

                </CardBody>
            </Card>
        </Row>
    );
}

export default Paiement;
