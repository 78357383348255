import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";

function Colis() {
    const [filter, setFilter] = useState({});
    const [trajets, setTrajets] = useState([]);

    const columns = [
        ["date de départ", "go_time"],
        ["addresse de départ", "from_address.name"],
        ["date d'arrivé", "arrive_time"],
        ["addresse d'arrivé", "to_address.name"],
        ["prix publié", "offre.prix"],
        ["etet", "etat"],
        ["Operations", "operations"]
    ];
    const colisCols = [
        ["qte", "qte"],
        ["image", 'photos'],
        ["title", "title"],
        ["width", "width"],
        ["height", "height"],
        ["length", "length"],
        ["weight", "weight"]
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("fetching data")
                let url = `${process.env.REACT_APP_SERVER}/trajets/all`;
                if (filter.from_address || filter.to_address) {
                    url = url + `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
                }
                const trajetResponse = await axios.get(url);
                const trajetData = trajetResponse.data;
                console.log(trajetData)
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        TrajetItem.arrive_time = new Date(TrajetItem.arrive_time).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        TrajetItem.go_time = new Date(TrajetItem.go_time).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null; // Returning null to indicate failure
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log(filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [filter]);
    // This function will redirect to another page
    function updateRow(index) {
        const id = trajets[index]._id;
        window.location.href = `/update/${id}`;
    }

    async function removeRow(index) {
        const id = trajets[index]._id;
        try {
            const response = await axios.delete(`${process.env.REACT_APP_SERVER}/trajets/${id}`);
            if (response.status === 200) {
                // If the deletion was successful, remove the item from the local trajets array too
                trajets.splice(index, 1);
                // Then update the state to cause a re-render
                setTrajets([...trajets]); // replace 'setTrajets' with your actual state update function
            } else {
                console.error('Failed to delete item:', response);
            }
        } catch (error) {
            console.error('Failed to delete item:', error);
        }
    }
    return (
        <Row>
            <Card className="Card" style={{ minWidth: "600px" }}>
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Liste des colis</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {trajets.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent${index}`} aria-expanded="false" aria-controls={`accordionContent${index}`}>
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle">
                                                    {column[1] === "operations" ? (
                                                        <div className="row m-0">

                                                            <button className="btn btn-outline-danger btn-sm my-1" onClick={() => removeRow(index)}>Supprmier</button>
                                                        </div>
                                                    ) : column[1] === "from_address.name" ? (
                                                        row.from_address.name ? row.from_address.name : "Introuvable"
                                                    ) : column[1] === "to_address.name" ? (
                                                        row.to_address.name ? row.to_address.name : "Introuvable"
                                                    ) : column[1] === "offre.prix" ? (
                                                        row.prix ? row.prix : "Introuvable"
                                                    ) : (
                                                        row[column[1]] || "Introuvable"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                        <tr id={`accordionContent${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                            <td colSpan={columns.length} >
                                                <Table>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {row.colis.map((colis) => (
                                                            <tr key={colis._id}>
                                                                {colisCols.map(column => (
                                                                    <td key={column[1]} >
                                                                        {column[1] === "photos" ? (
                                                                            <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                        ) : (
                                                                            colis[column[1]] || "Introuvable"
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </Row>
    );
}

export default Colis;
