import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import classes from "./style.module.css"
import { RelaiMapSelection } from "../../../components/map/map";
import { ca } from "date-fns/locale";
function MesColis() {
    const [demandes, setDemandes] = useState([]);
    const [postules, setPostules] = useState([]);
    const [rotatedArrows1, setRotatedArrows1] = useState({});
    const [rotatedArrows2, setRotatedArrows2] = useState({});
    const [selectedOffre, setSelectedOffre] = useState(null)
    const [relai1, setRelai1] = useState(false)
    const [relai2, setRelai2] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState()
    const [offre, setOffre] = useState(null);
    const accepter = async () => {
        try {
            const id = selectedOffre.offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/accepter/${id}`);
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }

    const submit = async () => {
        try {
            let oldOffre = demandes[selectedIndex].offre.offre
            const id = selectedOffre.offre._id
            selectedOffre.offre.offre = oldOffre
            console.log(`Updating offre ${id}`);
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${id}`, selectedOffre.offre);
            console.log("Offre updated");
            console.log(response.data);
            fetchData2();
        } catch (error) {
            console.error("Error updating offre:", error);
        }
    }
    const handleRelaiSelection1 = (place) => {
        console.log("Setting relai1");
        let offreSelection = structuredClone(selectedOffre);
        offreSelection.offre.offre.relai1 = place
        offreSelection.offre.offre.mam1 = false
        setSelectedOffre(offreSelection)
    };
    const handleRelaiSelection2 = (place) => {
        console.log("Setting relai2");
        let offreSelection = structuredClone(selectedOffre);
        offreSelection.offre.offre.relai2 = place
        offreSelection.offre.offre.mam2 = false
        setSelectedOffre(offreSelection)
    };
    const handleToggle1 = (index) => {
        setRotatedArrows1((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle rotation state for first list
        }));
    };
    const handleOffreSelection = async (index) => {
        let offreSelection = structuredClone(demandes[index]);
        console.log(offreSelection)
        setSelectedIndex(index)
        const n1 = await getRelai(offreSelection.offre.counterOffre.relai1)
        const n2 = await getRelai(offreSelection.offre.counterOffre.relai2)
        const n3 = await getRelai(offreSelection.offre.offre.relai1)
        const n4 = await getRelai(offreSelection.offre.offre.relai2)
        let offre = offreSelection
        offre.offre.counterOffre.relai1 = n1.address
        offre.offre.counterOffre.relai2 = n2.address
        offre.offre.offre.relai1 = n3
        offre.offre.offre.relai2 = n4
        setSelectedOffre(offre)
        setOffre(offreSelection.offre)

        console.log(demandes[index])
        if (offreSelection && offreSelection.offre && offreSelection.offre.offre && offreSelection.offre.offre.relai2) {
            setRelai2(true)
        }
        else {
            setRelai2(false)
        }
        if (offreSelection && offreSelection.offre && offreSelection.offre.offre && offreSelection.offre.offre.relai1) {
            setRelai1(true)
        }
        else {
            setRelai1(false)
        }
        console.log(offreSelection)
    }
    const getRelai = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data
            }).catch((err) => {
                console.log(err)
            });
            return address
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const handleToggle2 = (index) => {
        setRotatedArrows2((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle rotation state for second list
        }));
    };
    const columns2 = [
        ["date de départ", "go_time"],
        ["addresse de départ", "from_address.name"],
        ["date d'arrivé", "arrive_time"],
        ["addresse d'arrivé", "to_address.name"],
        ["prix envoyé", "offre.prix"],
        ["prix offert", "counteroffre.prix"],
        ["etat", "etat"],
        ["Operations", "operations"]
    ];
    const columns = [
        ["date de départ", "go_time"],
        ["addresse de départ", "from_address.name"],
        ["date d'arrivé", "arrive_time"],
        ["addresse d'arrivé", "to_address.name"],
        ["prix publié", "offre.prix"],
        ["etat", "etat"],
        ["Operations", "operations"]
    ];
    const colisCols = [
        ["qte", "qte"],
        ["image", 'photos'],
        ["title", "title"],
        ["width", "width"],
        ["height", "height"],
        ["length", "length"],
        ["weight", "weight"]
    ]
    const fetchData1 = async () => {
        try {

            const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/UserTrajets`);
            const trajetData = trajetResponse.data;

            const promises = trajetData.map(async (TrajetItem) => {
                try {
                    const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                    TrajetItem.colis = colisResponse.data ?? [];

                    return TrajetItem;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error);
                    return null; // Returning null to indicate failure
                }
            });

            const updateddemandesData = await Promise.all(promises);
            const filtereddemandesData = updateddemandesData.filter(item => item !== null);
            console.log(filtereddemandesData)
            setPostules(filtereddemandesData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData2 = async () => {
        let offresData = []
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres`).then((res) => {
                offresData = res.data;
                console.log(offresData);
            })
            const promises = offresData.map(async (OffreItem) => {
                try {
                    let trajet = {}
                    await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${OffreItem.ttrajet}`)
                        .then(async (res) => {
                            trajet = res.data
                            trajet.colis = []
                            trajet.offre = OffreItem
                            for (let coli of OffreItem.colis) {
                                await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                                    .then((res) => {
                                        trajet.colis.push(res.data)
                                    })
                            }
                        });
                    return trajet;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error.message);
                    return null; // Returning null to indicate failure
                }
            });
            const updateddemandesData = await Promise.all(promises);
            const filtereddemandesData = updateddemandesData.filter(item => item !== null);
            console.log(filtereddemandesData)
            setDemandes(filtereddemandesData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData = async () => {
        await fetchData1();
        await fetchData2();
    }
    useEffect(() => {

        fetchData()
    }, []);
    async function removeRow2(index) {
        try {
            let response;
            console.log(demandes[index]);
            if (demandes[index].transporteur) {
                const id = demandes[index].offre._id;

                // Delete the "TtrajetOffres" and handle errors
                try {
                    for (let i of demandes[index].offre.colis) {
                        try {
                            console.log(`Deleting colis ${i}`);
                            await axios.delete(`${process.env.REACT_APP_SERVER}/colis/${i}`);
                        } catch (colisError) {
                            console.error(`Failed to delete colis with id ${i}:`, colisError);
                        }
                    }
                    response = await axios.delete(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${id}`);

                    // If the delete was successful, delete all associated "colis"

                } catch (offreError) {
                    console.error(`Failed to delete TtrajetOffres with id ${id}:`, offreError);
                    return; // Exit early if there's an error with the main delete request
                }
            }

            // Check if the response is successful and update the state
            if (response && response.status === 200) {
                demandes.splice(index, 1);
                setDemandes([...demandes]);
            } else {
                console.error('Failed to delete item:', response);
            }
        } catch (error) {
            console.error('Unexpected error while deleting item:', error);
        }
    }

    async function removeRow1(index) {
        try {
            console.log(postules[index]);

            const id = postules[index]._id;

            // Delete the "trajets" and handle errors
            try {
                const response = await axios.delete(`${process.env.REACT_APP_SERVER}/trajets/${id}`);

                // Check if the response is successful and update the state
                if (response.status === 200) {
                    postules.splice(index, 1);
                    setPostules([...postules]); // Fix to update "postules" not "demandes"
                } else {
                    console.error('Failed to delete item:', response);
                }
            } catch (deleteError) {
                console.error(`Failed to delete trajet with id ${id}:`, deleteError);
            }
        } catch (error) {
            console.error('Unexpected error while deleting item:', error);
        }
    }

    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active w-50" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">Postulé</h5> </button>
                    <button className="nav-link w-50" id="nav-demande-tab" data-bs-toggle="tab" data-bs-target="#nav-demande" type="button" role="tab" aria-controls="nav-demande" aria-selected="false"><h5 className="text-start fw-lighter m-0">Demandé</h5> </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Mes colis postulés</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {postules.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <div className="row m-0">

                                                                <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => removeRow1(index)}>Supprimer</button>
                                                            </div>
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.prix ? row.prix : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td
                                                    onClick={() => handleToggle1(index)}
                                                    colSpan={columns.length}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#accordionContent1${index}`}
                                                    aria-expanded="true"
                                                    aria-controls={`accordionContent1${index}`}
                                                    className={`m-0 pt-0 border-bottom-0 text-center ${rotatedArrows1[index] ? 'arrow-rotated' : ''}`}
                                                >
                                                    <div className="text-center position-relative p-1" style={{ height: '0px' }}>
                                                        <div style={{ borderTop: '1px solid #dee2e6' }}></div>
                                                        <svg
                                                            className={`position-absolute start-50 translate-middle `}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                                fill="#999"
                                                            />
                                                            <path
                                                                className={`${classes.arrow_path} ${rotatedArrows1[index] ? classes.arrow_rotated : ''}`} // Rotate the arrow only if it's toggled
                                                                d="M15.5299 11.97C15.2399 11.68 14.7599 11.68 14.4699 11.97L12.7499 13.69V8.5C12.7499 8.09 12.4099 7.75 11.9999 7.75C11.5899 7.75 11.2499 8.09 11.2499 8.5V13.69L9.52994 11.97C9.23994 11.68 8.75994 11.68 8.46994 11.97C8.17994 12.26 8.17994 12.74 8.46994 13.03L11.4699 16.03C11.6199 16.18 11.8099 16.25 11.9999 16.25C12.1899 16.25 12.3799 16.18 12.5299 16.03L15.5299 13.03C15.8199 12.74 15.8199 12.26 15.5299 11.97Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr id={`accordionContent1${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                <td colSpan={columns.length} >
                                                    <Table>
                                                        <thead className="text-primary">
                                                            <tr>
                                                                {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {row.colis.map((colis) => (
                                                                <tr key={colis._id}>
                                                                    {colisCols.map(column => (
                                                                        <td key={column[1]} >
                                                                            {column[1] === "photos" ? (
                                                                                <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                            ) : (
                                                                                colis[column[1]] || "Introuvable"
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item disabled">
                                        <a className="page-link">Previous</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </CardBody>
                    </Card>

                </div>
                <div className="tab-pane fade" id="nav-demande" role="tabpanel" aria-labelledby="nav-demande-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Mes colis Demandés</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns2.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {demandes.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns2.map(column => (
                                                    <td key={column[1]} className=" align-middle  border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.etat === "accepté" ? (
                                                                    <>
                                                                        <button className="btn btn-outline-primary btn-sm my-1 col-12">Suivi</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12">Discuter</button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button className="btn btn-outline-primary btn-sm my-1 col-12" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleOffreSelection(index)}>Voir</button>
                                                                        <button className="btn btn-outline-danger my-1 col-12 btn-sm" onClick={() => removeRow2(index)}>Supprimer</button>
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.offre.prix ? row.offre.offre.prix : "Introuvable"
                                                        ) : column[1] === "counteroffre.prix" ? (
                                                            row.offre?.counterOffre?.prix ? row.offre.counterOffre.prix : "Introuvable"
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td
                                                    onClick={() => handleToggle2(index)}
                                                    colSpan={columns.length}
                                                    className={`m-0 pt-0 border-bottom-0 text-center ${rotatedArrows2[index] ? 'arrow-rotated' : ''}`}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#accordionContent2${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`accordionContent2${index}`}
                                                >
                                                    <div className="text-center position-relative p-1" style={{ height: '0px' }}>
                                                        <div style={{ borderTop: '1px solid #dee2e6' }}></div>
                                                        <svg
                                                            className={`position-absolute start-50 translate-middle ${rotatedArrows2[index] ? classes.arrow_rotated : ''}`}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                                fill="#999"
                                                            />
                                                            <path
                                                                className={`${classes.arrow_path} ${rotatedArrows2[index] ? classes.arrow_rotated : ''}`} // Rotate the arrow only if it's toggled
                                                                d="M15.5299 11.97C15.2399 11.68 14.7599 11.68 14.4699 11.97L12.7499 13.69V8.5C12.7499 8.09 12.4099 7.75 11.9999 7.75C11.5899 7.75 11.2499 8.09 11.2499 8.5V13.69L9.52994 11.97C9.23994 11.68 8.75994 11.68 8.46994 11.97C8.17994 12.26 8.17994 12.74 8.46994 13.03L11.4699 16.03C11.6199 16.18 11.8099 16.25 11.9999 16.25C12.1899 16.25 12.3799 16.18 12.5299 16.03L15.5299 13.03C15.8199 12.74 15.8199 12.26 15.5299 11.97Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr id={`accordionContent2${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                <td colSpan={columns.length} >
                                                    <Table>
                                                        <thead className="text-primary">
                                                            <tr>
                                                                {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {row.colis.map((colis) => (
                                                                <tr key={colis._id}>
                                                                    {colisCols.map(column => (
                                                                        <td key={column[1]} >
                                                                            {column[1] === "photos" ? (
                                                                                <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                            ) : (
                                                                                colis[column[1]] || "Introuvable"
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <div className="modal modal-xl fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" >
                            <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                                <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                                    <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">Consultre l'offre</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" >
                                    <div className="row">
                                        <div className="col ">
                                            <div className="card rounded-5 p-3 h-100" >


                                                <h5 className="card-title">Offre reçu</h5>
                                                <div className="card-body">
                                                    <h6 className="fw-bold">DÉPART :</h6>
                                                    <p>
                                                        Récupérer main à main : {selectedOffre && selectedOffre.offre.counterOffre.mam1 === true ? "Oui" : "Non"}
                                                    </p>
                                                    <p>
                                                        Récupérer en point relais : {(selectedOffre && selectedOffre.offre.counterOffre.relai1) ? (selectedOffre.offre.counterOffre.relai1) : ("Non")}
                                                    </p>
                                                    <h6 className="fw-bold">ARRIVÉ :</h6>
                                                    <p>
                                                        Déposer main à main : {selectedOffre && selectedOffre.offre.counterOffre.mam2 === true ? "Oui" : "Non"}
                                                    </p>
                                                    <p>
                                                        Déposer en point relais : {(selectedOffre && selectedOffre.offre.counterOffre.relai2) ? (selectedOffre.offre.counterOffre.relai2) : ("Non")}
                                                    </p>
                                                    <div> <b>PRIX : </b> {(selectedOffre && selectedOffre.offre.counterOffre.prix) ? (selectedOffre.offre.counterOffre.prix) : ("Introuvable")}</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card rounded-5 p-3 h-100" >
                                                <h5 className="card-title">Votre offre</h5>
                                                <div className="card-body">
                                                    <fieldset>
                                                        <h6 className="fw-bold">DÉPART</h6>
                                                        <div className="d-flex">
                                                            <p className="me-4">Récupérer main à main :</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam1" // Separate name for this group
                                                                    id="r1"
                                                                    value="oui"
                                                                    checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.offre && selectedOffre.offre.offre.mam1}
                                                                    onChange={(event) => {
                                                                        let offreSelection = structuredClone(selectedOffre);
                                                                        offreSelection.offre.offre.mam1 = true;
                                                                        setSelectedOffre(offreSelection);

                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r1">Oui</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam1"
                                                                    id="r2"
                                                                    value="non"
                                                                    checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.offre && !selectedOffre.offre.offre.mam1}
                                                                    onChange={(event) => {
                                                                        let offreSelection = structuredClone(selectedOffre);
                                                                        offreSelection.offre.offre.mam1 = false;
                                                                        setSelectedOffre(offreSelection);
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r2">Non</label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <p className="me-4">Récupérer en point relais :</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="relai1" // Separate name for this group
                                                                    id="r3"
                                                                    value="oui"
                                                                    checked={relai1}

                                                                    onChange={(event) => {
                                                                        setRelai1(true);
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r3">Oui</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="relai1"
                                                                    id="r4"
                                                                    value="non"
                                                                    checked={!relai1}

                                                                    onChange={(event) => {
                                                                        setRelai1(false);
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r4">Non</label>
                                                            </div>
                                                        </div>
                                                        {relai1 === true && (
                                                            <button
                                                                className="btn btn-outline-primary col-12 mb-4"
                                                                data-bs-target="#modalToggle2" data-bs-toggle="modal">
                                                                Selectionner une point relais
                                                            </button>
                                                        )}
                                                    </fieldset>

                                                    <fieldset>
                                                        <h6 className="fw-bold">ARRIVÉ</h6>

                                                        <div className="d-flex">
                                                            <p className="me-4">Déposer main à main :</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam2" // Separate name for this group
                                                                    id="r5"
                                                                    value="oui"
                                                                    checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.offre && selectedOffre.offre.offre.mam2}

                                                                    onChange={(event) => {
                                                                        let offreSelection = structuredClone(selectedOffre);
                                                                        offreSelection.offre.offre.mam2 = true;
                                                                        setSelectedOffre(offreSelection);
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r5">Oui</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="mam2"
                                                                    id="r6"
                                                                    value="non"
                                                                    checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.offre && !selectedOffre.offre.offre.mam2}
                                                                    onChange={(event) => {
                                                                        let offreSelection = structuredClone(selectedOffre);
                                                                        offreSelection.offre.offre.mam2 = false;
                                                                        setSelectedOffre(offreSelection);
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r6">Non</label>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex">
                                                            <p className="me-4">Déposer en point relais :</p>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="relai2" // Separate name for this group
                                                                    id="r7"
                                                                    value="oui"
                                                                    checked={relai2}
                                                                    onChange={(event) => {
                                                                        setRelai2(true);
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r7">Oui</label>
                                                            </div>
                                                            <div className="form-check me-4">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="relai2"
                                                                    id="r8"
                                                                    value="non"
                                                                    checked={!relai2}
                                                                    onChange={(event) => {
                                                                        setRelai2(false);
                                                                    }}
                                                                />
                                                                <label className="form-check-label" htmlFor="r8">Non</label>
                                                            </div>
                                                        </div>
                                                        {relai2 === true && (
                                                            <button className="btn btn-outline-primary col-12 mb-4" data-bs-target="#modalToggle3" data-bs-toggle="modal">Selectionner une point relais</button>

                                                        )}
                                                    </fieldset>
                                                    <fieldset>
                                                        <h6 className="fw-bold">PRIX</h6>
                                                        <input className="form-control" type="number" placeholder="Saisir le prix" value={selectedOffre?.offre?.offre?.prix ?? ''}
                                                            onChange={(event) => {
                                                                let offreSelection = structuredClone(selectedOffre);
                                                                offreSelection.offre.offre.prix = Number(event.target.value);
                                                                setSelectedOffre(offreSelection);
                                                            }} />
                                                    </fieldset>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"
                                        onClick={() => {
                                            setSelectedIndex(null)
                                            setSelectedOffre(undefined)
                                        }}>Annuler</button>
                                    <button type="button" className="btn btn-outline-success" data-bs-target="#modalToggle4" data-bs-toggle="modal"
                                        onClick={() => {

                                        }}>Accepter</button>
                                    <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={() => {
                                        submit()
                                        setSelectedIndex(null)
                                        setSelectedOffre(undefined)
                                    }}>Envoyer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade modal-xl" id="modalToggle2" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                                    <button type="button" className="btn-close" data-bs-target="#exampleModal" data-bs-toggle="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ height: "70vh" }}>
                                    <RelaiMapSelection
                                        setRelai={handleRelaiSelection1}
                                        nextModalId="exampleModal"
                                        location={selectedOffre?.offre?.offre?.relai1?.location ?? selectedOffre?.from_address?.location}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-target="#exampleModal" data-bs-toggle="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade modal-xl" id="modalToggle3" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                                    <button type="button" className="btn-close" data-bs-target="#exampleModal" data-bs-toggle="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ height: "70vh" }}>
                                    <RelaiMapSelection
                                        setRelai={handleRelaiSelection2}
                                        nextModalId="exampleModal"
                                        location={selectedOffre?.offre?.offre?.relai2?.location ?? selectedOffre?.to_address?.location}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-target="#exampleModal" data-bs-toggle="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade " id="modalToggle4" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Accepter l'offre</h1>
                                    <button type="button" className="btn-close" data-bs-target="#exampleModal" data-bs-toggle="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" >

                                    <p className="fs-5 text-danger">
                                        Êtes-vous sûr de vouloir accepter cette offre avec ces détails ?
                                    </p>
                                    <h6 className="fw-bold">DÉPART :</h6>
                                    <p>
                                        Récupérer main à main : {selectedOffre && selectedOffre.offre.counterOffre.mam1 === true ? "Oui" : "Non"}
                                    </p>
                                    <p>
                                        Récupérer en point relais : {(selectedOffre && selectedOffre.offre.counterOffre.relai1) ? (selectedOffre.offre.counterOffre.relai1) : ("Non")}
                                    </p>
                                    <h6 className="fw-bold">ARRIVÉ :</h6>
                                    <p>
                                        Déposer main à main : {selectedOffre && selectedOffre.offre.counterOffre.mam2 === true ? "Oui" : "Non"}
                                    </p>
                                    <p>
                                        Déposer en point relais : {(selectedOffre && selectedOffre.offre.counterOffre.relai2) ? (selectedOffre.offre.counterOffre.relai2) : ("Non")}
                                    </p>
                                    <div> <b>PRIX : </b> {(selectedOffre && selectedOffre.offre.counterOffre.prix) ? (selectedOffre.offre.counterOffre.prix) : ("Introuvable")}</div>



                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-target="#exampleModal" data-bs-toggle="modal">Annuler</button>
                                    <button type="button" className="btn btn-outline-primary position-relative top-0 " data-bs-dismiss="modal"
                                        onClick={accepter}
                                    >
                                        Accepter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Row>
    );
}

export default MesColis;
