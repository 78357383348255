import React, { useState } from "react"
import ColisCard from "../../components/cards/colis/coli"
import { useLocation } from "react-router-dom";
import axios from "axios";
import Map from "../../components/map/map";
import classes from "./voir-_offre.module.css"
import dashed_line from "../../assets/dashed_line.svg"
import car from "../../assets/car.svg"
import { ca } from "date-fns/locale";

function VoirOffre() {

    console.log("Voir Offre")
    const location = useLocation();
    const trajet = location.state;
    const [offre, setOffre] = useState({ trajet: trajet, prix: trajet.prix })
    // Deserialize the string back into an object
    // Now you have access to the trajet parameter
    console.log(trajet.colis);
    const handleOffreSubmit = async (e) => {
        try {
            e.preventDefault()
            console.log("submiting offre")
            await axios.post(`${process.env.REACT_APP_SERVER}/offres`, offre)
        } catch (err) {
            console.log(err)
        }

    }
    return (
        <div className="container w-100 py-4" style={{ backgroundColor: "#F8F8F7" }}>


            <div className="row mt-3 justify-content-around" >
                <div className="col-12 col-lg-6 row ">
                    <p style={{ fontSize: "22px", fontWeight: "bold", color: "#1f406d" }}>Détails du trajets :</p>
                    <div className="card">
                        <div className="card-body row m-0 px-0 px-md-5">
                            <div className="col-3">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>Départ</p>
                                <p className="fw-semibold">{trajet.from_address.name},  {trajet.from_address.address_components[trajet.from_address.address_components.length - 1].long_name}</p>
                            </div>
                            <div className="col-6 text-center align-self-center" >
                                <img className="" src={car} />

                                <img className={`${classes.img}`} src={dashed_line} />
                            </div>
                            <div className="col-3">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>Arrivé</p>
                                <p className="fw-semibold">{trajet.to_address.name}, {trajet.to_address.address_components[trajet.to_address.address_components.length - 1].long_name}</p>
                            </div>
                            <div className="col-12 d-flex">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>Date de départ : </p>
                                <p className="ms-3 fw-normal"> Le {new Date(trajet.go_time).toLocaleDateString('fr-FR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</p>
                            </div>
                            <div className="col-12 d-flex">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>Date d'arrivé : </p>
                                <p className="ms-3 fw-normal"> Le {new Date(trajet.arrive_time).toLocaleDateString('fr-FR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</p>
                            </div>
                            <div className="col-12 d-flex">
                                <p className="fw-semibold" style={{ color: "#1f406d" }}>Prix publié : </p>
                                <p className="ms-3 fw-normal"> {trajet.prix} €</p>
                            </div>



                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 d-flex align-items-center ">
                                <div className="input-group">
                                    <label className="input-group-text">Faire un offre </label>
                                    <input type="number"
                                        value={offre.prix} // Check if trajet.offre is not null before accessing its properties
                                        onChange={(e) => setOffre({ ...offre, prix: e.target.value })}
                                        className="form-control"
                                        placeholder="" />
                                    <button type="button" className="btn text-nowrap w-auto " style={{ color: "white", backgroundColor: "#FFCA40" }} onClick={(e) => handleOffreSubmit(e)}>Envoyer</button>
                                </div>

                            </div>
                        </div >
                    </div>
                    <p className="mt-4" style={{ fontSize: "22px", fontWeight: "bold", color: "#1f406d" }}>Liste des colis :</p>

                    <div className="col-12 row m-0">
                        {
                            trajet.colis.map(coli => {
                                return <div className="col-12 m-0"> < ColisCard coli={coli} /></div>
                            })
                        }
                    </div>
                </div >
                <div className="col-12 col-lg-4">
                    <Map trajet={trajet} style={"directions"} />
                </div>


            </div ></div>
    )

}
export default VoirOffre