import React, { useEffect, useState } from "react";
import Map from "../../components/map/map";
import Mapinput from "../../components/map/input";
import classes from "./voir_colis_disponible.module.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

const VoirColisDispo = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { place1, place2 } = location.state || {}
    const [trajets, setTrajets] = useState([]);
    const [selectedPlace1, setSelectedPlace1] = useState(place1 || {});
    const [selectedPlace2, setSelectedPlace2] = useState(place2 || {});
    const [filter, setFilter] = useState({
        from_address: selectedPlace1,
        to_address: selectedPlace2
    });
    const weightList = [
        "< 5KG",
        "Entre 5KG et 30KG ",
        "Entre 30KG et 50KG ",
        "Entre 50KG et 100KG ",
        ">100KG"
    ];
    console.log(selectedPlace1, place2)
    const handlePlaceSelection1 = (place) => {
        setSelectedPlace1(place);
        setFilter({ ...filter, from_address: place });
    };

    const handlePlaceSelection2 = (place) => {
        setSelectedPlace2(place);
        setFilter({ ...filter, to_address: place });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/trajets`;
                if (filter.from_address || filter.to_address) {
                    url = url + `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
                }
                const trajetResponse = await axios.get(url);
                console.log(trajetResponse.data)
                const trajetData = trajetResponse.data;
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null;
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);

                // Filter out the null values representing failed requests
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);

                // Now filteredTrajetsData contains only successfully fetched data
                console.log(filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [filter]);

    return (
        <>
            <div className={`${classes.sidebar} z-2 position-absolute p-3`}>
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <Mapinput firstValue={selectedPlace1?.name} onChange={handlePlaceSelection1} placeholder={"Ville de départ"} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Mapinput firstValue={selectedPlace2?.name} onChange={handlePlaceSelection2} placeholder={"Ville d'arrivée"} />
                        </Col>
                    </Row>
                    {trajets.map((trajet) => (
                        trajet.colis.map((coli) => (
                            <div className="col d-flex justify-content-center">
                                <div className={`${classes.card} card mb-3 shadow border border-0 overflow-auto w-100`} style={{ maxWidth: "550PX" }} onClick={() => { navigate(`/voirOffre`, { state: trajet }) }}>
                                    <div className="row g-0">
                                        <div className=" col-2 col-md-3 d-flex align-items-center p-2">
                                            <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(coli.photos[0])}`} className="img-fluid rounded-start" style={{ maxHeight: "160px" }} alt="..." />
                                        </div>
                                        <div className="col-6 ">
                                            <div className="card-body ">
                                                <p className="card-title text-capitalize fw-bold" style={{ color: "#1f406d", fontSize: "22px" }}>{coli.title}</p>
                                                <div className='d-flex'>
                                                    <div className='d-flex flex-column justify-content-center text-center' style={{ width: "20px" }}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fill-opacity="0.4" />
                                                            <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fill-opacity="0.4" />
                                                        </svg>
                                                        <svg className='align-self-center' width="2" height="50" viewBox="0 0 2 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clipRule="evenodd" d="M1 0C1.55228 0 2 0.621827 2 1.38889V48.6111C2 49.3782 1.55228 50 1 50C0.447715 50 0 49.3782 0 48.6111V1.38889C0 0.621827 0.447715 0 1 0Z" fill="black" fill-opacity="0.4" />
                                                        </svg>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fill-opacity="0.4" />
                                                            <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fill-opacity="0.4" />
                                                        </svg>
                                                    </div>
                                                    <div className=' d-flex flex-column align-items-start text-start' style={{ height: "90px" }}>
                                                        <p className='m-0 fw-medium'>{trajet.from_address.name} le {new Date(trajet.go_time).toLocaleDateString('fr-FR', {
                                                            month: 'numeric',
                                                            day: 'numeric',
                                                        })}</p>
                                                        <p className='m-0 fw-medium mt-auto'>{trajet.to_address.name} le {new Date(trajet.arrive_time).toLocaleDateString('fr-FR', {
                                                            month: 'numeric',
                                                            day: 'numeric',
                                                        })}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-3">
                                            <div className="card-body">
                                                <p className="fw-bold" style={{ color: "#1f406d", fontSize: "22px" }}>{trajet.prix}  €</p>
                                                <div className='mt-auto border-start border-2 border-secondary-subtle ps-1'>
                                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Qte : {coli.qte}</p>
                                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Dim : {coli.length} x {coli.width} x {coli.height} cm</p>
                                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Poids: {weightList[coli.weight]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))
                    )
                    )}
                </Container>
            </div >
            <div style={{ height: "100vh", overflow: "auto" }} className="z-1 position-relative rounded-3">
                {/* Pass trajets data to the Map component */}
                <Map data={trajets} />
            </div>
        </>
    );
};

export default VoirColisDispo;
