import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import Map from "../../../components/map/map";

function Livraisons() {
    const [trajets, setTrajets] = useState([]);

    const columns = [
        ["date de départ", "go_time"],
        ["addresse de départ", "from_address.name"],
        ["date d'arrivé", "arrive_time"],
        ["addresse d'arrivé", "to_address.name"],
        ["prix", "offre.prix"],
        ["etat", "etat"],
        ["operations", "operations"]];
    const colisCols = [
        ["qte", "qte"],
        ["image", 'photos'],
        ["title", "title"],
        ["width", "width"],
        ["height", "height"],
        ["length", "length"],
        ["weight", "weight"]
    ]
    useEffect(() => {
        const fetchData = async () => {
            try {
                let url = `${process.env.REACT_APP_SERVER}/trajets/AllLivres`;

                const trajetResponse = await axios.get(url);
                const trajetData = trajetResponse.data;

                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        const offreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/offres/livre?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        TrajetItem.offre = offreResponse.data ?? [];
                        TrajetItem.arrive_time = new Date(TrajetItem.arrive_time).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        TrajetItem.go_time = new Date(TrajetItem.go_time).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error.message);
                        return null; // Returning null to indicate failure
                    }
                });
                const updatedTrajetsData = await Promise.all(promises);
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log(filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);





    return (
        <Row>
            <Card className="Card" style={{ minWidth: "600px" }}>
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">liste des livraisons</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {trajets.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent${index}`} aria-expanded="false" aria-controls={`accordionContent${index}`}>
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle">
                                                    {column[1] === "from_address.name" ? (
                                                        row.from_address.name ? row.from_address.name : "Introuvable"
                                                    ) : column[1] === "to_address.name" ? (
                                                        row.to_address.name ? row.to_address.name : "Introuvable"
                                                    ) : column[1] === "offre.prix" ? (
                                                        row.offre.prix ? row.offre.prix : "Introuvable"
                                                    ) : column[1] === "operations" ? (
                                                        <></>
                                                    ) : (
                                                        row[column[1]] || "Introuvable"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                        <tr id={`accordionContent${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                            <td colSpan={columns.length} >
                                                <Table>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {row.colis.map((colis) => (
                                                            <tr key={colis._id}>
                                                                {colisCols.map(column => (
                                                                    <td key={column[1]} >
                                                                        {column[1] === "photos" ? (
                                                                            <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                        ) : (
                                                                            colis[column[1]] || "Introuvable"
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <div className="mb-3 mt-1 w-100 p-0">
                                                    <button
                                                        className="btn btn-outline-primary w-100"
                                                        type="button"
                                                        onClick={() => window.location.href = `/suivi/${row.offre.user}`}
                                                    >
                                                        Suivre
                                                    </button>
                                                    <div className="collapse" id="collapseExample">
                                                        <div className="card card-body">
                                                            <form >
                                                                <div className="input-group">
                                                                    <div className="w-75">
                                                                        <Map style="small" />
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </Row>
    );
}

export default Livraisons;
