import { Autocomplete } from "@react-google-maps/api";
import React, { useEffect, useState } from "react"

export default function Mapinput({ onChange, placeholder, firstValue }) {
    const [place, setPlace] = useState(null);
    const handlePlaceChanged = () => {
        if (place !== null) {
            let newPlace = {}
            try {
                const selectedPlace = place.getPlace();
                setInputValue(newPlace.name);
                console.log(selectedPlace)
                let location = {
                    "lat": selectedPlace.geometry.location.lat(),
                    "lng": selectedPlace.geometry.location.lng()
                };
                let name = selectedPlace.name
                let components = selectedPlace.address_components
                newPlace = { "name": name, "location": location, "address_components": components };

            }
            catch (e) {
                console.error(e);
            }
            finally {
                onChange(newPlace)
            }
        }
    };
    const [inputValue, setInputValue] = useState(firstValue);

    useEffect(() => {
        setInputValue(firstValue)
    }, [firstValue])
    return (
        <Autocomplete className="col-12 "
            style={{ backgroundColor: "white", zIndex: "2000" }}
            onLoad={autocomplete => setPlace(autocomplete)}
            onPlaceChanged={handlePlaceChanged}
        >
            <input
                type="text"
                className="form-control"
                placeholder={placeholder || "Saisir une adresse"}
                value={inputValue}
                onChange={event => setInputValue(event.target.value)}
                required
            />
        </Autocomplete >
    )
}
export function Relaisinput({ onChange, placeholder, firstValue }) {
    const [place, setPlace] = useState(null);
    const handlePlaceChanged = () => {
        if (place !== null) {
            let newPlace = {}
            try {
                const selectedPlace = place.getPlace();
                setInputValue(newPlace.name);
                console.log(selectedPlace)
                let location = {
                    "lat": selectedPlace.geometry.location.lat(),
                    "lng": selectedPlace.geometry.location.lng()
                };
                let address = selectedPlace.formatted_address
                let name = selectedPlace.name
                let components = selectedPlace.address_components
                newPlace = {
                    "name": name,
                    "location": location,
                    "address_components": components,
                    "address": address
                };

            }
            catch (e) {
                console.error(e);
            }
            finally {
                onChange(newPlace)
            }
        }
    };
    const [inputValue, setInputValue] = useState(firstValue);

    useEffect(() => {
        setInputValue(firstValue)
    }, [firstValue])
    return (
        <Autocomplete className="col-12 "
            style={{ backgroundColor: "white", zIndex: "2000" }}
            onLoad={autocomplete => setPlace(autocomplete)}
            onPlaceChanged={handlePlaceChanged}
        >
            <input
                type="text"
                className="form-control"
                placeholder={placeholder || "Saisir une adresse"}
                value={inputValue}
                onChange={event => setInputValue(event.target.value)}
                required
            />
        </Autocomplete >
    )
}