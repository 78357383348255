import React, { useState } from "react";

import InscriptionImg from "../../assets/home_inscription.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Creercompte = () => {
    const navigate = useNavigate()

    const [formData, setFormData] = React.useState({ email: '', password: '' })
    const onSubmit = (e) => {
        e.preventDefault()
        if (handlepswcheck())
            axios.post(`${process.env.REACT_APP_SERVER}/users/register`, formData)
                .then((res) => {
                    if (res.status === 200) {
                        navigate("/connection")
                    }
                })
                .catch((error) => {
                    if (error.response.status === 400)
                        console.log(error.response.status);
                })

    }
    const [psw, setPsw] = useState()
    const handlepswcheck = () => {
        if (psw !== formData.password) {
            return false
        }
        return true
    }
    return (
        <div className="bg-gradient-primary">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex">
                                        <div className="flex-grow-1 bg-login-image ">
                                            <img src={InscriptionImg} alt="connection" className="w-100 " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">Bienvenue!</h4>
                                            </div>
                                            <form className="user" onSubmit={onSubmit}>
                                                <div className="row mb-3">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input className="form-control form-control-user" type="text" id="exampleFirstName" placeholder="Prénom" name="first_name"
                                                            required onChange={(e) => setFormData({ ...formData, prenom: e.target.value })} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input required className="form-control form-control-user" type="text" id="exampleLastName" placeholder="Nom" name="last_name"
                                                            onChange={(e) => setFormData({ ...formData, nom: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="mb-3"><input className="form-control form-control-user" type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Saisir votre adresse Email" name="email"
                                                    required onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input className="form-control form-control-user" type="password" id="exampleFirstName" placeholder="Mot de passe" name="password"
                                                            required onChange={(e) => setFormData({ ...formData, password: e.target.value })} /></div>
                                                    <div className="col-sm-6">
                                                        <input className="form-control form-control-user" type="password" placeholder="Répeter mots de passe" name="repeat_password"
                                                            required onChange={(e) => setPsw(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row  mt-2 mb-3">
                                                    <div className={`row m-0`}>
                                                        <input
                                                            className={`btn-check col-12 col-sm`}
                                                            type="radio"
                                                            name={`type`}
                                                            value="particulier"
                                                            id={`type1`}
                                                            onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                                                        />
                                                        <label
                                                            className={`btn btn-outline-primary col-12 col-sm`}
                                                            htmlFor={`type1`}
                                                        >
                                                            Particulier
                                                        </label>


                                                        <input
                                                            className={`btn-check col-12 col-sm`}
                                                            type="radio"
                                                            name={`type`}
                                                            value="transporteur"

                                                            id={`type3`}
                                                            onChange={(e) => setFormData({ ...formData, role: e.target.value })} />
                                                        <label
                                                            className={`btn btn-outline-primary col-12 col-sm `}
                                                            htmlFor={`type3`}
                                                        >
                                                            Transporteur
                                                        </label>
                                                        <div className="invalid-feedback col-12">
                                                            Choisissez le poids.
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className="btn btn-primary d-block btn-user w-100" type="submit">Créer Compte</button>
                                                <hr />
                                            </form>
                                            <div className="text-center"><a className="small" href="connection">Connecter!</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

};

export default Creercompte;