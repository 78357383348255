import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import axios from "axios";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table
} from "reactstrap";
import ChatBox from "../components/chatbox/chatbox";
import { RelaiMapSelection } from "../../../components/map/map";
import classes from "./style.module.css"
import { ca } from "date-fns/locale";

function OffresRecus() {
    const [selectedTrajet, setSelectedTrajet] = useState(null);
    const [offre, setOffre] = useState(null);
    const [rotatedArrows1, setRotatedArrows1] = useState({});
    const [rotatedArrows2, setRotatedArrows2] = useState({});

    const handleToggle1 = (index) => {
        setRotatedArrows1((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle rotation state for first list
        }));
    };

    const handleToggle2 = (index) => {
        setRotatedArrows2((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle rotation state for second list
        }));
    }
    const columns1 = [
        ["date de départ", "go_time"],
        ["addresse de départ", "from_address.name"],
        ["date d'arrivé", "arrive_time"],
        ["addresse d'arrivé", "to_address.name"],
        ["prix publié", "trajet.prix"],
        ["prix offert", "offre.prix"],
        ["etat", "offre.etat"],
        ["Operations", "operations"]
    ];
    const columns2 = [
        ["date de départ", "go_time"],
        ["addresse de départ", "from_address.name"],
        ["date d'arrivé", "arrive_time"],
        ["addresse d'arrivé", "to_address.name"],
        ["prix offert", "offre.prix"],
        ["prix envoyé", "counteroffre.prix"],

        ["etat", "offre.etat"],
        ["Operations", "operations"]
    ];
    const colisCols = [
        ["qte", "qte"],
        ["image", 'photos'],
        ["title", "title"],
        ["width", "width"],
        ["height", "height"],
        ["length", "length"],
        ["weight", "weight"]
    ]
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [relai1, setRelai1] = useState(false)
    const [relai2, setRelai2] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState()
    const [selectedOffre, setSelectedOffre] = useState(null)
    const fetchData1 = async () => {
        try {
            console.log("fetching offers");
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/offres`);
            console.log("offers received");
            console.log(response)
            const data = await Promise.all(response.data.map(async offre => {
                offre.date_creation = new Date(offre.date_creation).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
                const res = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/trajet?trajet=${offre.trajet}`);
                let trajet = res.data
                const res1 = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${offre.trajet}`)
                console.log("trajet", trajet)
                trajet.offre = offre
                trajet.colis = res1.data
                console.log("offers received", trajet);
                return trajet;
            }));
            console.log(data);
            setData1(data);
        } catch (error) {
            console.error("Error fetching offers:", error.message);
            return [];
        }
    };
    const fetchData2 = async () => {
        try {
            console.log("fetching offers");
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres/recieved`);
            console.log("offers received");
            const data = await Promise.all(response.data.map(async offre => {
                offre.date_creation = new Date(offre.date_creation).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
                const colis = await Promise.all(offre.colis.map(async coli => {
                    const res1 = await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                    return res1.data
                }))
                const res2 = await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${offre.ttrajet}`)
                let trajet = res2.data
                trajet.colis = colis
                trajet.offre = offre
                console.log(trajet)
                return trajet;
            }));
            console.log(data);
            setData2(data);
        } catch (error) {
            console.error("Error fetching offers:", error.message);
            return [];
        }
    };
    const updateRow = async (index, isAccepted) => {
        try {
            const id = data1[index].offre._id;
            const trajet = data1[index]._id
            console.log(`Updating offre ${id}`);
            const newEtat = isAccepted ? "accepté" : "réfusé";
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/offres/${id}`, { etat: newEtat });
            console.log("Offre updated");
            console.log(response.data);
            if (newEtat === "accepté") {
                await axios.put(`${process.env.REACT_APP_SERVER}/trajet/${trajet}`, { etat: newEtat });
            }
        } catch (error) {
            console.error("Error updating offre:", error);
        }
        fetchData1();
    }
    const accepter = async () => {
        try {
            const id = selectedOffre.offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/accepter/${id}`);
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    useEffect(() => {
        fetchData1()
        fetchData2()
    }, []);
    const submit = async () => {
        try {
            let oldOffre = data2[selectedIndex].offre.offre
            const id = selectedOffre.offre._id
            selectedOffre.offre.offre = oldOffre
            console.log(`Updating offre ${id}`);
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${id}`, selectedOffre.offre);
            console.log("Offre updated");
            console.log(response.data);
            fetchData2();
        } catch (error) {
            console.error("Error updating offre:", error);
        }
    }
    const handleRelaiSelection1 = (place) => {
        console.log("Setting relai1");
        let offreSelection = structuredClone(selectedOffre);
        offreSelection.offre.counterOffre.relai1 = place
        offreSelection.offre.counterOffre.mam1 = false
        setSelectedOffre(offreSelection)
    };
    const handleRelaiSelection2 = (place) => {
        console.log("Setting relai2");
        let offreSelection = structuredClone(selectedOffre);
        offreSelection.offre.counterOffre.relai2 = place
        offreSelection.offre.counterOffre.mam2 = false
        setSelectedOffre(offreSelection)
    };
    const getRelai = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data
            }).catch((err) => {
                console.log(err)
            });
            return address
        } catch (err) { console.log(err) }
    }
    const handleOffreSelection = async (index) => {
        let offreSelection = structuredClone(data2[index]);
        setSelectedIndex(index)
        const n1 = await getRelai(offreSelection.offre.offre.relai1)
        const n2 = await getRelai(offreSelection.offre.offre.relai2)
        const n3 = await getRelai(offreSelection.offre.counterOffre.relai1)
        const n4 = await getRelai(offreSelection.offre.counterOffre.relai2)
        let offre = offreSelection
        console.log(offre)
        offre.offre.offre.relai1 = n1 ? n1.address : "Non"
        offre.offre.offre.relai2 = n2 ? n2.address : "Non"
        offre.offre.counterOffre.relai1 = n3
        offre.offre.counterOffre.relai2 = n4
        setSelectedOffre(offre)
        setOffre(offreSelection.offre)
        console.log(data2[index])
        if (offreSelection && offreSelection.offre && offreSelection.offre.counterOffre && offreSelection.offre.counterOffre.relai2) {
            setRelai2(true)
        }
        else {
            setRelai2(false)
        }
        if (offreSelection && offreSelection.offre && offreSelection.offre.counterOffre && offreSelection.offre.counterOffre.relai1) {
            setRelai1(true)
        }
        else {
            setRelai1(false)
        }
    }
    const handleRowClick = (index) => {
        // Open the chat box with the user associated with the row
        const row = data1[index];
        console.log(row.trajet);
        setSelectedTrajet(row.trajet);
    };
    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link w-50 active" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">Mes colis</h5> </button>
                    <button className="nav-link w-50" id="nav-trajet-tab" data-bs-toggle="tab" data-bs-target="#nav-trajet" type="button" role="tab" aria-controls="nav-trajet" aria-selected="false"><h5 className="text-start fw-lighter m-0">Mes trajet</h5> </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Offres sur mes colis</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns1.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {data1.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr  >
                                                {columns1.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "demandé" ? (
                                                                    <div >
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => { updateRow(index, "accepté") }} >Accepter</button>
                                                                        <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => { updateRow(index, "refusé") }}>Refuser</button>
                                                                    </div>
                                                                ) : row.offre.etat === "refusé" ? (
                                                                    <button className="btn btn-outline-danger  btn-sm my-1 col-12">Supprimer</button>
                                                                ) : (
                                                                    <>
                                                                        <button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => handleRowClick(index)}>Discuter</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => {
                                                                            console.log(row)
                                                                            window.location.href = `/suivi/${row.offre.user}`
                                                                        }}
                                                                        >Suivi</button></>
                                                                )}
                                                            </>

                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "trajet.prix" ? (
                                                            row.prix ? row.prix : "Introuvable"
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.prix ? row.offre.prix : "Introuvable"
                                                        ) : column[1] === "offre.etat" ? (
                                                            row.offre.etat ? row.offre.etat : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td
                                                    onClick={() => handleToggle1(index)}
                                                    colSpan={columns1.length}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#accordionContent1${index}`}
                                                    aria-expanded="true"
                                                    aria-controls={`accordionContent1${index}`}
                                                    className={`m-0 pt-0 border-bottom-0 text-center ${rotatedArrows1[index] ? 'arrow-rotated' : ''}`}
                                                >
                                                    <div className="text-center position-relative p-1" style={{ height: '0px' }}>
                                                        <div style={{ borderTop: '1px solid #dee2e6' }}></div>
                                                        <svg
                                                            className={`position-absolute start-50 translate-middle `}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                                fill="#999"
                                                            />
                                                            <path
                                                                className={`${classes.arrow_path} ${rotatedArrows1[index] ? classes.arrow_rotated : ''}`} // Rotate the arrow only if it's toggled
                                                                d="M15.5299 11.97C15.2399 11.68 14.7599 11.68 14.4699 11.97L12.7499 13.69V8.5C12.7499 8.09 12.4099 7.75 11.9999 7.75C11.5899 7.75 11.2499 8.09 11.2499 8.5V13.69L9.52994 11.97C9.23994 11.68 8.75994 11.68 8.46994 11.97C8.17994 12.26 8.17994 12.74 8.46994 13.03L11.4699 16.03C11.6199 16.18 11.8099 16.25 11.9999 16.25C12.1899 16.25 12.3799 16.18 12.5299 16.03L15.5299 13.03C15.8199 12.74 15.8199 12.26 15.5299 11.97Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id={`accordionContent1${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                <td colSpan={columns1.length} >
                                                    <Table>
                                                        <thead className="text-primary">
                                                            <tr>
                                                                {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {row.colis.map((colis) => (
                                                                <tr key={colis._id}>
                                                                    {colisCols.map(column => (
                                                                        <td key={column[1]} >
                                                                            {column[1] === "photos" ? (
                                                                                <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                            ) : (
                                                                                colis[column[1]] || "Introuvable"
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item disabled">
                                        <a className="page-link">Previous</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </CardBody>
                    </Card>
                </div >
                <div className="tab-pane fade" id="nav-trajet" role="tabpanel" aria-labelledby="nav-trajet-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Offres sur mes trajets</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns2.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {data2.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns2.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "demandé" ? (

                                                                    <>
                                                                        <button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => handleOffreSelection(index)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                            Voir</button>
                                                                        <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => updateRow(index, false)}>Réfuser</button>
                                                                    </>
                                                                ) : row.offre.etat === "accepté" ? (
                                                                    <>   <button className="btn btn-outline-primary btn-sm my-1" onClick={() => handleRowClick(index)}>Discuter</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1" onClick={() => window.location.href = `/suivi/${row.user}`}
                                                                        >Suivi</button>
                                                                    </>
                                                                ) : (
                                                                    <button className="btn btn-outline-danger my-1 btn-sm col-12">Supprimer</button>
                                                                )}
                                                            </>
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.offre.prix ? row.offre.offre.prix : "Introuvable"
                                                        ) : column[1] === "counteroffre.prix" ? (
                                                            row.offre?.counterOffre?.prix ? row.offre.counterOffre.prix : "Introuvable"
                                                        ) : column[1] === "offre.etat" ? (
                                                            row.offre.etat ? row.offre.etat : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td
                                                    onClick={() => handleToggle2(index)}
                                                    colSpan={columns2.length}
                                                    className={`m-0 pt-0 border-bottom-0 text-center ${rotatedArrows2[index] ? 'arrow-rotated' : ''}`}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#accordionContent2${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`accordionContent2${index}`}
                                                >
                                                    <div className="text-center position-relative p-1" style={{ height: '0px' }}>
                                                        <div style={{ borderTop: '1px solid #dee2e6' }}></div>
                                                        <svg
                                                            className={`position-absolute start-50 translate-middle ${rotatedArrows2[index] ? classes.arrow_rotated : ''}`}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                                fill="#999"
                                                            />
                                                            <path
                                                                className={`${classes.arrow_path} ${rotatedArrows2[index] ? classes.arrow_rotated : ''}`} // Rotate the arrow only if it's toggled
                                                                d="M15.5299 11.97C15.2399 11.68 14.7599 11.68 14.4699 11.97L12.7499 13.69V8.5C12.7499 8.09 12.4099 7.75 11.9999 7.75C11.5899 7.75 11.2499 8.09 11.2499 8.5V13.69L9.52994 11.97C9.23994 11.68 8.75994 11.68 8.46994 11.97C8.17994 12.26 8.17994 12.74 8.46994 13.03L11.4699 16.03C11.6199 16.18 11.8099 16.25 11.9999 16.25C12.1899 16.25 12.3799 16.18 12.5299 16.03L15.5299 13.03C15.8199 12.74 15.8199 12.26 15.5299 11.97Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id={`accordionContent2${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                <td colSpan={columns2.length} >
                                                    <Table>
                                                        <thead className="text-primary">
                                                            <tr>
                                                                {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {row.colis.map((colis) => (
                                                                <tr key={colis._id}>
                                                                    {colisCols.map(column => (
                                                                        <td key={column[1]} >
                                                                            {column[1] === "photos" ? (
                                                                                <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(colis.photos[0])}`} style={{ maxHeight: "40px" }} alt="Coli" />

                                                                            ) : (
                                                                                colis[column[1]] || "Introuvable"
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </React.Fragment>

                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
                <div className="modal modal-xl fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                            <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                                <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">Consultre l'offre</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" >
                                <div className="row">
                                    <div className="col ">
                                        <div className="card rounded-5 p-3 h-100" >


                                            <h5 className="card-title">Offre reçu</h5>
                                            <div className="card-body">
                                                <h6 className="fw-bold">DÉPART :</h6>
                                                <p>
                                                    Récupérer main à main : {selectedOffre && selectedOffre.offre.offre.mam1 === true ? "Oui" : "Non"}
                                                </p>
                                                <p>
                                                    Récupérer en point relais : {(selectedOffre && selectedOffre.offre.offre.relai1) ? (selectedOffre.offre.offre.relai1) : ("Non")}
                                                </p>
                                                <h6 className="fw-bold">ARRIVÉ :</h6>
                                                <p>
                                                    Déposer main à main : {selectedOffre && selectedOffre.offre.offre.mam2 === true ? "Oui" : "Non"}
                                                </p>
                                                <p>
                                                    Déposer en point relais : {(selectedOffre && selectedOffre.offre.offre.relai2) ? (selectedOffre.offre.offre.relai2) : ("Non")}
                                                </p>
                                                <div> <b>PRIX : </b> {(selectedOffre && selectedOffre.offre.offre.prix) ? (selectedOffre.offre.offre.prix) : ("Introuvable")}</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card rounded-5 p-3 h-100" >
                                            <h5 className="card-title">Votre offre</h5>
                                            <div className="card-body">
                                                <fieldset>
                                                    <h6 className="fw-bold">DÉPART</h6>
                                                    <div className="d-flex">
                                                        <p className="me-4">Récupérer main à main :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam1" // Separate name for this group
                                                                id="r1"
                                                                value="oui"
                                                                checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.counterOffre && selectedOffre.offre.counterOffre.mam1}
                                                                onChange={(event) => {
                                                                    let offreSelection = structuredClone(selectedOffre);
                                                                    offreSelection.offre.counterOffre.mam1 = true;
                                                                    setSelectedOffre(offreSelection);

                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r1">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam1"
                                                                id="r2"
                                                                value="non"
                                                                checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.counterOffre && !selectedOffre.offre.counterOffre.mam1}
                                                                onChange={(event) => {
                                                                    let offreSelection = structuredClone(selectedOffre);
                                                                    offreSelection.offre.counterOffre.mam1 = false;
                                                                    setSelectedOffre(offreSelection);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r2">Non</label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="me-4">Récupérer en point relais :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai1" // Separate name for this group
                                                                id="r3"
                                                                value="oui"
                                                                checked={relai1}

                                                                onChange={(event) => {
                                                                    setRelai1(true);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r3">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai1"
                                                                id="r4"
                                                                value="non"
                                                                checked={!relai1}

                                                                onChange={(event) => {
                                                                    setRelai1(false);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r4">Non</label>
                                                        </div>
                                                    </div>
                                                    {relai1 === true && (
                                                        <button
                                                            className="btn btn-outline-primary col-12 mb-4"
                                                            data-bs-target="#modalToggle2" data-bs-toggle="modal">
                                                            Selectionner une point relais
                                                        </button>
                                                    )}
                                                </fieldset>

                                                <fieldset>
                                                    <h6 className="fw-bold">ARRIVÉ</h6>

                                                    <div className="d-flex">
                                                        <p className="me-4">Déposer main à main :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam2" // Separate name for this group
                                                                id="r5"
                                                                value="oui"
                                                                checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.counterOffre && selectedOffre.offre.counterOffre.mam2}

                                                                onChange={(event) => {
                                                                    let offreSelection = structuredClone(selectedOffre);
                                                                    offreSelection.offre.counterOffre.mam2 = true;
                                                                    setSelectedOffre(offreSelection);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r5">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam2"
                                                                id="r6"
                                                                value="non"
                                                                checked={selectedOffre && selectedOffre.offre && selectedOffre.offre.counterOffre && !selectedOffre.offre.counterOffre.mam2}
                                                                onChange={(event) => {
                                                                    let offreSelection = structuredClone(selectedOffre);
                                                                    offreSelection.offre.counterOffre.mam2 = false;
                                                                    setSelectedOffre(offreSelection);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r6">Non</label>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex">
                                                        <p className="me-4">Déposer en point relais :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai2" // Separate name for this group
                                                                id="r7"
                                                                value="oui"
                                                                checked={relai2}
                                                                onChange={(event) => {
                                                                    setRelai2(true);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r7">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai2"
                                                                id="r8"
                                                                value="non"
                                                                checked={!relai2}
                                                                onChange={(event) => {
                                                                    setRelai2(false);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r8">Non</label>
                                                        </div>
                                                    </div>
                                                    {relai2 === true && (
                                                        <button className="btn btn-outline-primary col-12 mb-4" data-bs-target="#modalToggle3" data-bs-toggle="modal">Selectionner une point relais</button>

                                                    )}
                                                </fieldset>
                                                <fieldset>
                                                    <h6 className="fw-bold">PRIX</h6>
                                                    <input className="form-control" type="number" placeholder="Saisir le prix" value={selectedOffre?.offre?.counterOffre?.prix ?? ''}
                                                        onChange={(event) => {
                                                            let offreSelection = structuredClone(selectedOffre);
                                                            offreSelection.offre.counterOffre.prix = Number(event.target.value);
                                                            setSelectedOffre(offreSelection);
                                                        }} />
                                                </fieldset>
                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"
                                    onClick={() => {
                                        setSelectedIndex(null)
                                        setSelectedOffre(undefined)
                                    }}>Annuler</button>
                                <button type="button" className="btn btn-outline-success" data-bs-target="#modalToggle4" data-bs-toggle="modal"
                                    onClick={() => {

                                    }}>Accepter</button>
                                <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={() => {
                                    submit()
                                    setSelectedIndex(null)
                                    setSelectedOffre(undefined)
                                }}>Envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal-xl" id="modalToggle2" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                                <button type="button" className="btn-close" data-bs-target="#exampleModal" data-bs-toggle="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" style={{ height: "70vh" }}>
                                <RelaiMapSelection
                                    setRelai={handleRelaiSelection1}
                                    nextModalId="exampleModal"
                                    location={selectedOffre?.offre?.counterOffre?.relai1?.location ?? selectedTrajet?.from_address?.location}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" data-bs-target="#exampleModal" data-bs-toggle="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal-xl" id="modalToggle3" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                                <button type="button" className="btn-close" data-bs-target="#exampleModal" data-bs-toggle="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" style={{ height: "70vh" }}>
                                <RelaiMapSelection
                                    setRelai={handleRelaiSelection2}
                                    nextModalId="exampleModal"
                                    location={selectedOffre?.offre?.counterOffre?.relai2?.location ?? selectedTrajet?.to_address?.location}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" data-bs-target="#exampleModal" data-bs-toggle="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade " id="modalToggle4" aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Accepter l'offre</h1>
                                <button type="button" className="btn-close" data-bs-target="#exampleModal" data-bs-toggle="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" >

                                <p className="fs-5 text-danger">
                                    Êtes-vous sûr de vouloir accepter cette offre avec ces détails ?
                                </p>
                                <h6 className="fw-bold">DÉPART :</h6>
                                <p>
                                    Récupérer main à main : {selectedOffre && selectedOffre.offre.offre.mam1 === true ? "Oui" : "Non"}
                                </p>
                                <p>
                                    Récupérer en point relais : {(selectedOffre && selectedOffre.offre.offre.relai1) ? (selectedOffre.offre.offre.relai1) : ("Non")}
                                </p>
                                <h6 className="fw-bold">ARRIVÉ :</h6>
                                <p>
                                    Déposer main à main : {selectedOffre && selectedOffre.offre.offre.mam2 === true ? "Oui" : "Non"}
                                </p>
                                <p>
                                    Déposer en point relais : {(selectedOffre && selectedOffre.offre.offre.relai2) ? (selectedOffre.offre.offre.relai2) : ("Non")}
                                </p>
                                <div> <b>PRIX : </b> {(selectedOffre && selectedOffre.offre.offre.prix) ? (selectedOffre.offre.offre.prix) : ("Introuvable")}</div>



                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" data-bs-target="#exampleModal" data-bs-toggle="modal">Annuler</button>
                                <button type="button" className="btn btn-outline-primary position-relative top-0 " data-bs-dismiss="modal"
                                    onClick={accepter}
                                >
                                    Accepter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {
                selectedTrajet && <ChatBox selectedTrajet={selectedTrajet} />
            }

        </Row >
    );
}

export default OffresRecus;
