function Footer() {
    return (
        <div className="mt-5 position-relative z-2">
            <footer className="text-white text-center text-lg-start" style={{ backgroundColor: "#d8a942 " }}>
                <div className="container p-4">
                    <div className="row mt-4">
                        <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                            <p className="text-uppercase mb-4 fs-4" style={{ color: "#1f406d" }}>à propres nous</p>

                            <p>
                                Colispo est la première plateforme au monde dédiée au covoiturage de colis entre particuliers. Notre service met en relation des personnes souhaitant expédier des colis à moindre coût et des conducteurs prêts à transporter ces envois lors de leurs trajets.
                            </p>
                            <div className="mt-4">
                                <a type="button" className="btn btn-floating btn-lg" style={{ backgroundColor: "#d8a942 " }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-facebook" viewBox="0 0 17 17">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                    </svg>
                                </a>
                                <a type="button" className="btn btn-floating btn-lg" style={{ backgroundColor: "#d8a942 " }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 17 17">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                    </svg>
                                </a>
                                <a type="button" className="btn btn-floating btn-lg" style={{ backgroundColor: "#d8a942 " }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-google" viewBox="0 0 17 17">
                                        <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                            <p className="text-uppercase mb-4 fs-4 pb-1" style={{ color: "#1f406d" }}>Contactez nous</p>


                            <ul className="fa-ul" style={{ marginLeft: "1.65em" }}>
                                <li className="mb-3">
                                    <span className="fa-li"><i className="fas fa-home"></i></span><span className="ms-2">Paris, France</span>
                                </li>
                                <li className="mb-3">
                                    <span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">colispo_team@colispo.com</span>
                                </li>
                                <li className="mb-3">
                                    <span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">+ 01 234 567 88</span>
                                </li>
                                <li className="mb-3">
                                    <span className="fa-li"><i className="fas fa-print"></i></span><span className="ms-2">+ 01 234 567 89</span>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                            <p className="text-uppercase mb-4 fs-4 pb-1" style={{ color: "#1f406d" }}>Envoyer Email</p>

                            <div className="mb-4">
                                <input type="text" id="formControlLg" placeholder="Votre e-mail" className="form-control form-control-lg" />
                                <textarea className="form-control mt-3" id="exampleFormControlTextarea1" rows="3" placeholder="Votre message"></textarea>
                                <button type="button" className="btn fw-bold btn-outline-light mt-3 ms-auto w-auto" style={{ color: "#1f406d" }}>Envoyer</button>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="text-center p-3" style={{ background: "rgb(0,0,0,0.2)" }}>
                    © 2024 Copyright:
                    <a className="text-white" href="/">colispo.com</a>
                </div>
            </footer>

        </div>
    )
}
export default Footer;