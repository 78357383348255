import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

function ChatBox({ selectedTrajet }) {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const token = Cookies.get("_auth");
    var messageIds = useRef(new Set());
    useEffect(() => {
        setMessages([]);
        messageIds.current = new Set();
    }, [selectedTrajet]);
    useEffect(() => {
        const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/messages?trajet=${selectedTrajet}&token=${token}`);
        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (Array.isArray(data)) {
                // This is a list of initial messages
                const newMessages = data.filter(message => !messageIds.current.has(message._id));
                setMessages((prevMessages) => [...prevMessages, ...newMessages]);
                newMessages.forEach(message => messageIds.current.add(message._id));
            } else {
                // This is a single new message
                const message = data;

                if (!messageIds.current.has(message._id)) {
                    setMessages((prevMessages) => [...prevMessages, message]);
                    messageIds.current.add(message._id);
                }
            }
        };
        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
        };
        console.log(messages)
    }, [selectedTrajet, token]);

    const handleSendMessage = () => {
        // Send the new message
        console.log("sending message")
        axios.post(`${process.env.REACT_APP_SERVER}/messages`, { selectedTrajet, text: newMessage })
            .then(response => {
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
    };

    return (
        <div className="container py-5">
            <div className="row d-flex justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-4">
                    <div className="card" id="chat1" style={{ borderRadius: '15px' }}>
                        <div className="card-header d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
                            style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                            <i className="fas fa-angle-left"></i>
                            <p className="mb-0 fw-bold">Live chat</p>
                            <i className="fas fa-times"></i>
                        </div>
                        <div className="card-body">
                            {messages.map((message, index) => (
                                <div key={index} className="d-flex flex-row justify-content-start mb-4">
                                    {message.sender ? (

                                        <div className="d-flex flex-row justify-content-end mb-4 ms-auto">
                                            <div className="p-3 me-3 border" style={{ borderRadius: "15px", backgroundColor: "#fbfbfb" }}>
                                                <p className="small mb-0">{message.content}</p>
                                            </div>
                                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                                                alt="avatar 1" style={{ width: "45px", height: "100%" }} />
                                        </div>

                                    ) : (

                                        <div className="d-flex flex-row justify-content-end mb-4">

                                            <div className="p-3 ms-3" style={{ borderRadius: '15px', backgroundColor: 'rgba(57, 192, 237,.2)' }}>
                                                <p className="small mb-0">{message.content}</p>
                                            </div>
                                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                                                alt="avatar 2" style={{ width: '45px', height: '100%' }} />
                                        </div>

                                    )}
                                </div>
                            ))}
                            <div data-mdb-input-init className="form-outline">

                                <textarea className="form-control d-block" id="textAreaExample" rows="4" value={newMessage} onChange={(e) => setNewMessage(e.target.value)}></textarea>
                                <div className='input-group'>
                                    <label className="form-label " htmlFor="textAreaExample">Type your message</label>
                                    <button className='btn btn-primary ms-auto' onClick={handleSendMessage}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ChatBox;