import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

function EditerBlog() {
    const { id } = useParams();
    const [blog, setBlog] = useState({ content: "", card: { title: "", description: "", image: "" } });
    const [selectedImage, setSelectedImage] = useState(null);

    const reactQuillRef = useRef(null); // Use useRef to create a reference

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBlog(prevBlog => ({
            ...prevBlog,
            card: {
                ...prevBlog.card,
                [name]: value
            }
        }));
    };

    const modules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                ['link', 'image', 'video'],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': ['#1f406d', '#fff', '#00000'] }, { 'background': [] }], // color and background
                ['clean']
            ]
        },
    };

    const save = async () => {
        try {
            console.log(blog);
            let card = blog.card;
            if (selectedImage !== null) {
                if (blog.card.image) {
                    await axios.delete(`${process.env.REACT_APP_SERVER}/files/${blog.card.image}`).catch((error) => {
                        console.error("Error deleting data:", error);
                    });
                }
                let formData = new FormData();
                formData.append('file', selectedImage);
                const res = await axios.post(`${process.env.REACT_APP_SERVER}/files`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log(res.data);
                card.image = res.data.fileName;
                console.log(card);
            }
            console.log("saving data");
            let url = `${process.env.REACT_APP_SERVER}/blogs/${id}`;
            const response = await axios.put(url, { content: blog.content, card: card });
            console.log(response);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("fetching data");
                let url = `${process.env.REACT_APP_SERVER}/blogs/${id}`;
                const blogResponse = await axios.get(url);
                const blogData = blogResponse.data;
                console.log(blogData);
                setBlog(blogData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [id]);

    const handleChange = (content) => {
        setBlog(prevBlog => ({
            ...prevBlog,
            content: content
        }));
    };

    return (
        <div>
            <Card className="Card">
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Editer Blog</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <ReactQuill
                        onChange={handleChange} // Update the content in state
                        ref={reactQuillRef} // Set the ref here
                        value={blog.content || ""} // Set the value of the editor
                        modules={modules} // Set the modules configuration
                        placeholder="Start writing your blog post here..."
                    />
                    <div className="row">
                        <div className='card col-12 col-md-7'>
                            <div className='card-body row'>
                                <div className='col-12 col-md-6 col-lg-5'>
                                    <label htmlFor='photo' className='row w-100 h-100 mx-auto'>
                                        {selectedImage !== null || (blog && blog.card && blog.card.image) ? (
                                            <img src={(selectedImage !== null) ? URL.createObjectURL(selectedImage) : `${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(blog.card.image)}`}
                                                alt="Selected" className='align-self-center m-auto' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                        ) : (
                                            <svg className='align-self-center m-auto align-items-center' width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M37.4998 44.7917C31.1665 44.7917 26.0415 39.6667 26.0415 33.3333C26.0415 27 31.1665 21.875 37.4998 21.875C43.8332 21.875 48.9582 27 48.9582 33.3333C48.9582 39.6667 43.8332 44.7917 37.4998 44.7917ZM37.4998 28.125C34.6248 28.125 32.2915 30.4583 32.2915 33.3333C32.2915 36.2083 34.6248 38.5417 37.4998 38.5417C40.3748 38.5417 42.7082 36.2083 42.7082 33.3333C42.7082 30.4583 40.3748 28.125 37.4998 28.125Z" fill="black" fill-opacity="0.88" />
                                                <path d="M62.5002 94.7918H37.5002C14.8752 94.7918 5.2085 85.1252 5.2085 62.5002V37.5002C5.2085 14.8752 14.8752 5.2085 37.5002 5.2085H54.1668C55.8752 5.2085 57.2918 6.62516 57.2918 8.3335C57.2918 10.0418 55.8752 11.4585 54.1668 11.4585H37.5002C18.2918 11.4585 11.4585 18.2918 11.4585 37.5002V62.5002C11.4585 81.7085 18.2918 88.5418 37.5002 88.5418H62.5002C81.7085 88.5418 88.5418 81.7085 88.5418 62.5002V41.6668C88.5418 39.9585 89.9585 38.5418 91.6668 38.5418C93.3752 38.5418 94.7918 39.9585 94.7918 41.6668V62.5002C94.7918 85.1252 85.1252 94.7918 62.5002 94.7918Z" fill="black" fill-opacity="0.88" />
                                                <path d="M88.5417 23.9585H65.625C63.9167 23.9585 62.5 22.5418 62.5 20.8335C62.5 19.1252 63.9167 17.7085 65.625 17.7085H88.5417C90.25 17.7085 91.6667 19.1252 91.6667 20.8335C91.6667 22.5418 90.25 23.9585 88.5417 23.9585Z" fill="black" fill-opacity="0.88" />
                                                <path d="M77.0835 35.4167C75.3752 35.4167 73.9585 34 73.9585 32.2917V9.375C73.9585 7.66667 75.3752 6.25 77.0835 6.25C78.7918 6.25 80.2085 7.66667 80.2085 9.375V32.2917C80.2085 34 78.7918 35.4167 77.0835 35.4167Z" fill="black" fill-opacity="0.88" />
                                                <path d="M11.1251 82.084C10.1251 82.084 9.12508 81.584 8.54175 80.709C7.58341 79.2923 7.95842 77.334 9.37508 76.3756L29.9168 62.584C34.4168 59.584 40.6251 59.9173 44.7085 63.3756L46.0835 64.584C48.1668 66.3756 51.7085 66.3756 53.7501 64.584L71.0835 49.709C75.5418 45.9173 82.4585 45.9173 86.9168 49.709L93.7085 55.5423C95.0001 56.6673 95.1668 58.6256 94.0418 59.959C92.9168 61.2506 90.9585 61.4173 89.6251 60.2923L82.8335 54.459C80.7501 52.6673 77.2085 52.6673 75.1668 54.459L57.8335 69.334C53.4168 73.1256 46.4585 73.1256 42.0001 69.334L40.6251 68.1256C38.7084 66.5006 35.5418 66.334 33.4168 67.7923L12.9167 81.584C12.3334 81.9173 11.7084 82.084 11.1251 82.084Z" fill="black" fill-opacity="0.88" />
                                            </svg>
                                        )}
                                    </label>
                                    <input type='file' id='photo' className='d-none' onChange={handleImageChange} />
                                </div>
                                <div className='col-12 col-md-6 col-lg-7'>
                                    <input type='text' className='form-control' name='title' placeholder='Titre de la page' value={blog.card.title || ""} onChange={handleInputChange} />
                                    <textarea className='form-control mt-2' rows={5} name='description' placeholder='Description de la page' value={blog.card.description || ""} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-outline-primary ms-auto" onClick={save}>Enregistrer</button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default EditerBlog;