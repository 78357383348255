import React from 'react';

function Unauthorized() {
    return (
        <div>
            <h1>Unauthorized</h1>
            <p>You do not have the necessary permissions to access this page.</p>
        </div>
    );
}

export default Unauthorized;