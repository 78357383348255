import classes from './coli.module.css'

export default function ColisCard({ coli }) {
    const weightList = [
        "< 5KG",
        "Entre 5KG et 30KG ",
        "Entre 30KG et 50KG ",
        "Entre 50KG et 100KG ",
        ">100KG"
    ];
    return (
        <div className={`${classes.card} card mb-3 shadow overflow-auto w-100`} style={{ maxWidth: "550PX" }} >
            <div className="row g-0 align-items-center border-bottom border-2 border-secondary-subtle ps-1">
                <div className="col-1 text-center"><p style={{ fontSize: "20px", margin: "0" }} >{coli.qte}</p> </div>
                <div className="col-4 border-start border-2 border-secondary-subtle ps-1">
                    <div className="card-body ">
                        <p className="card-title text-capitalize fw-normal mb-0" style={{ fontSize: "20px" }}>{coli.title}</p>
                    </div>
                </div>
                <div className="col-6 border-start border-2 border-secondary-subtle ps-1">
                    <div className="card-body ">
                        <div className='mt-auto '>
                            <p style={{ fontSize: "16px", margin: "0" }}>Dimensions : {coli.length} x {coli.width} x {coli.height} cm</p>
                            <p style={{ fontSize: "16px", margin: "0" }}>Poids: {weightList[coli.weight]}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="d-flex">
                    {coli.photos.map((photo, index) => (
                        <div type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" >
                            <img src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(photo)}`} className="img-fluid rounded-start" style={{ maxHeight: "100px" }} alt="..." />
                        </div>
                    ))}
                </div>


                <div className="modal modal-xl fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Photos</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div id="carouselExampleIndicators" className="carousel slide carousel-dark">
                                <div className="carousel-indicators">
                                    {coli.photos.map((_, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to={index}
                                            className={index === 0 ? "active" : ""}
                                            aria-current={index === 0 ? "true" : "false"}
                                            aria-label={`Slide ${index + 1}`}
                                        ></button>
                                    ))}
                                </div>
                                <div className="carousel-inner">
                                    {coli.photos.map((photo, index) => (
                                        <div
                                            key={index}
                                            className={`carousel-item ${index === 0 ? "active" : ""}`}
                                        >
                                            <img
                                                src={`${process.env.REACT_APP_SERVER}/files?filePath=${encodeURIComponent(photo)}`}
                                                className="d-block w-100"
                                                style={{ maxHeight: "80vh", objectFit: "contain" }}
                                                alt={`Slide ${index + 1}`}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide="prev"
                                >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide="next"
                                >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div >
    )

}