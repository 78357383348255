import React from "react";

import InscriptionImg from "../../assets/home_inscription.jpg";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
const Connection = ({ SetIsAuthenticated }) => {
    const navigate = useNavigate()
    const signIn = useSignIn()
    const { path } = useParams();

    const [formData, setFormData] = React.useState({ email: '', password: '' })
    const onSubmit = async (e) => {
        try {
            console.log(process.env.REACT_APP_SERVER)

            e.preventDefault()
            await axios.post(`${process.env.REACT_APP_SERVER}/users/login`, formData)
                .then((res) => {
                    if (res.status === 200) {
                        let obj = {}
                        if (signIn({
                            auth: {
                                token: res.data.token,
                                type: 'Bearer'
                            },
                            userState: { userRole: res.data.userRole },
                            /* refresh: res.data.refreshToken*/
                        })) { // Only if you are using refreshToken feature
                            obj.auth = true
                            obj.role = res.data.userRole
                            console.log(res.data)
                            SetIsAuthenticated(obj)
                            console.log(res.data.userRole)
                            if (res.data.userRole === "admin" || res.data.userRole === "superadmin")
                                navigate("/Dashboard")
                            else if (path)
                                navigate("/" + path)
                            else navigate("/")


                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        catch (error) {
            console.error(error)
        }
    }
    return (
        <div className="bg-gradient-primary">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex">
                                        <div className="flex-grow-1 bg-login-image ">
                                            <img src={InscriptionImg} alt="connection" className="w-100 " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">Connecter!</h4>
                                            </div>
                                            <form className="user" onSubmit={onSubmit}>
                                                <div className="mb-3"><input className="form-control form-control-user" type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Saisir votre adresse Email"
                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                    required name="email" /></div>
                                                <div className="mb-3"><input className="form-control form-control-user" type="password" id="exampleInputPassword" placeholder="Saisir votre mot de passe"
                                                    required onChange={(e) => setFormData({ ...formData, password: e.target.value })} name="password" /></div>
                                                <div className="mb-3">

                                                </div>
                                                <button className="btn btn-primary d-block btn-user w-100" type="submit">Connecter</button>
                                                <hr />

                                            </form>
                                            <div className="text-center"><a className="small" href="/inscription">Créer un compte</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

};

export default Connection;