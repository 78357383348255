import React from "react";
import Rating from "react-rating";
import star from "../../assets/star.svg"
import empty_star from "../../assets/empty_star.svg"
const TransportFilter = ({ filters, onFilterChange, handleFilter }) => {
    const initialFilters = {
        transportModes: [],
        transportRating: 0,
        timeOfDeparture: '',
        timeOfArrival: ''
    }
    // Handle Transport Mode Change
    const handleTransportModeChange = (mode, isChecked) => {
        const updatedModes = isChecked
            ? [...filters.transportModes, mode]
            : filters.transportModes.filter((m) => m !== mode);
        onFilterChange({ ...filters, transportModes: updatedModes });
    };

    // Handle Rating Change
    const handleRatingChange = (rating) => {
        onFilterChange({ ...filters, transportRating: rating });
    };

    // Handle Time of Departure Change
    const handleTimeOfDepartureChange = (e) => {
        const value = e.target.value;
        onFilterChange({ ...filters, timeOfDeparture: value });
    };

    // Handle Time of Arrival Change
    const handleTimeOfArrivalChange = (e) => {
        const value = e.target.value;
        onFilterChange({ ...filters, timeOfArrival: value });
    };
    const handleResetFilters = () => {
        const resetFilters = { ...initialFilters };
        onFilterChange(resetFilters);
        handleFilter(resetFilters); // Fetch data with initial filters
    };
    const isFiltersEmpty = () => {
        return filters.transportModes.length === 0 && filters.transportRating === 0 && filters.timeOfDeparture === '' && filters.timeOfArrival === '';
    }
    return (
        <div className="p-3">
            {!isFiltersEmpty() && (
                <button className="btn btn-outline-danger col-12" onClick={handleResetFilters}>
                    Réinitialiser les filtres
                </button>
            )}
            <h5>Filtrer par :</h5>

            {/* Transport Mode */}
            <div className="mb-3">
                <label className="form-label">Mode de transport :</label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="modeAvion"
                        checked={filters.transportModes.includes("avion")}
                        onChange={(e) => handleTransportModeChange("avion", e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="modeAvion">Avion</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="modeCamion"
                        checked={filters.transportModes.includes("camion")}
                        onChange={(e) => handleTransportModeChange("camion", e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="modeCamion">Camion</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="modeVoiture"
                        checked={filters.transportModes.includes("voiture")}
                        onChange={(e) => handleTransportModeChange("voiture", e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="modeVoiture">Voiture</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="modeBateau"
                        checked={filters.transportModes.includes("bateau")}
                        onChange={(e) => handleTransportModeChange("bateau", e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="modeBateau">Bateau</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="modeTrain"
                        checked={filters.transportModes.includes("train")}
                        onChange={(e) => handleTransportModeChange("train", e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="modeTrain">Train</label>
                </div>
            </div>

            {/* Transport Rating */}
            <div className="mb-3">
                <label className="form-label">Note de transport :</label>
                <Rating
                    emptySymbol={
                        <img src={empty_star} className="icon" alt="empty star" />
                    }
                    fullSymbol={
                        <img src={star} className="icon" alt="full star" />
                    }
                    initialRating={filters.transportRating}
                    onChange={handleRatingChange}
                />
            </div>

            {/* Time of Departure */}
            <div className="mb-3">
                <label className="form-label">Temps de départ :</label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="timeOfDeparture"
                        checked={filters.timeOfDeparture === "morning"}
                        value="morning"
                        onChange={handleTimeOfDepartureChange}
                    />
                    <label className="form-check-label">Matin</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="timeOfDeparture"
                        value="evening"
                        checked={filters.timeOfDeparture === "evening"}
                        onChange={handleTimeOfDepartureChange}
                    />
                    <label className="form-check-label">Soir</label>
                </div>
            </div>

            {/* Time of Arrival */}
            <div className="mb-3">
                <label className="form-label">Temps d'arrivée :</label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="timeOfArrival"
                        value="morning"
                        checked={filters.timeOfArrival === "morning"}
                        onChange={handleTimeOfArrivalChange}
                    />
                    <label className="form-check-label">Matin</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="timeOfArrival"
                        value="evening"
                        checked={filters.timeOfArrival === "evening"}
                        onChange={handleTimeOfArrivalChange}
                    />
                    <label className="form-check-label">Soir</label>
                </div>
            </div>
            <button className="btn btn-outline-primary col-12" onClick={() => { handleFilter(filters) }}>Filtrer</button>
        </div>
    );
};

export default TransportFilter;
