
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function VerifyEmailToken() {
    const [message, setMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const params = new URLSearchParams(location.search);
                const token = params.get('token');

                // Send a request to your server to verify the email
                const response = await axios.post(`${process.env.REACT_APP_SERVER}/users/verify-email?ver=${token}`);
                if (response.ok) {
                    setMessage('Your email has been verified.');
                } else {
                    setMessage('An error occurred while trying to verify your email.');
                }
            } catch (error) {
                console.log(error);
            }
        };

        verifyEmail();
    }, [location]);

    return (
        <div>
            <h1>Email Verification</h1>
            {message && <p>{message}</p>}
        </div>
    );
}

export default VerifyEmailToken;