import { GoogleMap, Marker, MarkerF } from "@react-google-maps/api";
import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
function Suivi() {
    const [position, setPosition] = useState({ lat: 0, lng: 0 });
    const mapContainerStyle = {
        width: "100%",
        height: "100vh",
    };
    const { id } = useParams();
    const token = Cookies.get("_auth");
    const [zoom, setZoom] = useState(10);
    useEffect(() => {
        console.log('fetching position', id)
        const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/tracking/${id}?token=${token}`);
        eventSource.onmessage = (event) => {
            console.log('Event received:', event);
            const data = JSON.parse(event.data);
            console.log('position:', data);

            if (data && data.lat && data.lng) {
                setPosition({ lat: data.lat, lng: data.lng });
            }
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
        };

        // Close the EventSource connection when the component unmounts
        return () => {
            eventSource.close();
        };
    }, [id]);
    const options = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true
    };
    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={position}
            options={options}
        >
            <MarkerF position={position} />
        </GoogleMap>
    );
}

export default Suivi;