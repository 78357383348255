import { useState } from 'react'
import classes from './sidebar.module.css'
import "./toggle.css"
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

export default function Sidebar({ setFull }) {
    const [toggle, SetToggle] = useState(false)
    function OpCo() {
        SetToggle(!toggle)
        setFull(!toggle)
    }
    const user = useAuthUser()
    const userRole = user.userRole

    return (
        <>
            <div className={`${classes.togglebox} d-lg-none`}>
                <input id="checkbox2" type="checkbox" onClick={OpCo} />
                <label className="toggle toggle2" htmlFor="checkbox2">
                    <div id="bar4" className="bars"></div>
                    <div id="bar5" className="bars"></div>
                    <div id="bar6" className="bars"></div>
                </label>
            </div>
            <nav className={`col-12 col-md-6 col-lg-4 ${classes.nav1} ${toggle ? classes.nav1_open : ''}`}>
                <ul className="nav flex-column">
                    {userRole === "admin" && <>
                        < li className="nav-item" >
                            <a className="nav-link " aria-current="page" href="/dashboard/users">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.15963 11.62C9.12963 11.62 9.10963 11.62 9.07963 11.62C9.02963 11.61 8.95963 11.61 8.89963 11.62C5.99963 11.53 3.80963 9.25 3.80963 6.44C3.80963 3.58 6.13963 1.25 8.99963 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18963 11.62C9.17963 11.62 9.16963 11.62 9.15963 11.62ZM8.99963 2.75C6.96963 2.75 5.30963 4.41 5.30963 6.44C5.30963 8.44 6.86963 10.05 8.85963 10.12C8.91963 10.11 9.04963 10.11 9.17963 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99963 2.75Z" fill="white" fillOpacity="0.88" />
                                    <path d="M16.5396 11.75C16.5096 11.75 16.4796 11.75 16.4496 11.74C16.0396 11.78 15.6196 11.49 15.5796 11.08C15.5396 10.67 15.7896 10.3 16.1996 10.25C16.3196 10.24 16.4496 10.24 16.5596 10.24C18.0196 10.16 19.1596 8.96 19.1596 7.49C19.1596 5.97 17.9296 4.74 16.4096 4.74C15.9996 4.75 15.6596 4.41 15.6596 4C15.6596 3.59 15.9996 3.25 16.4096 3.25C18.7496 3.25 20.6596 5.16 20.6596 7.5C20.6596 9.8 18.8596 11.66 16.5696 11.75C16.5596 11.75 16.5496 11.75 16.5396 11.75Z" fill="white" fillOpacity="0.88" />
                                    <path d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z" fill="white" fillOpacity="0.88" />
                                    <path d="M18.3396 20.75C17.9896 20.75 17.6796 20.51 17.6096 20.15C17.5296 19.74 17.7896 19.35 18.1896 19.26C18.8196 19.13 19.3996 18.88 19.8496 18.53C20.4196 18.1 20.7296 17.56 20.7296 16.99C20.7296 16.42 20.4196 15.88 19.8596 15.46C19.4196 15.12 18.8696 14.88 18.2196 14.73C17.8196 14.64 17.5596 14.24 17.6496 13.83C17.7396 13.43 18.1396 13.17 18.5496 13.26C19.4096 13.45 20.1596 13.79 20.7696 14.26C21.6996 14.96 22.2296 15.95 22.2296 16.99C22.2296 18.03 21.6896 19.02 20.7596 19.73C20.1396 20.21 19.3596 20.56 18.4996 20.73C18.4396 20.75 18.3896 20.75 18.3396 20.75Z" fill="white" fillOpacity="0.88" />
                                </svg>
                                Users</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/colis">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9998 13.2999C11.8698 13.2999 11.7398 13.2699 11.6198 13.1999L2.78985 8.0899C2.42985 7.8799 2.30983 7.41987 2.51983 7.05987C2.72983 6.69987 3.17983 6.57985 3.54983 6.78985L11.9998 11.6799L20.3998 6.81988C20.7598 6.60988 21.2198 6.7399 21.4298 7.0899C21.6398 7.4499 21.5098 7.90987 21.1598 8.11987L12.3899 13.1999C12.2599 13.2599 12.1298 13.2999 11.9998 13.2999Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12 22.36C11.59 22.36 11.25 22.02 11.25 21.61V12.54C11.25 12.13 11.59 11.79 12 11.79C12.41 11.79 12.75 12.13 12.75 12.54V21.61C12.75 22.02 12.41 22.36 12 22.36Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12.0001 22.75C11.1201 22.75 10.2501 22.56 9.56012 22.18L4.22012 19.21C2.77012 18.41 1.64014 16.48 1.64014 14.82V9.16998C1.64014 7.50998 2.77012 5.59002 4.22012 4.78002L9.56012 1.82C10.9301 1.06 13.0701 1.06 14.4401 1.82L19.7801 4.78997C21.2301 5.58997 22.3601 7.51999 22.3601 9.17999V14.83C22.3601 16.49 21.2301 18.41 19.7801 19.22L14.4401 22.18C13.7501 22.56 12.8801 22.75 12.0001 22.75ZM12.0001 2.74999C11.3701 2.74999 10.7501 2.88 10.2901 3.13L4.95013 6.09997C3.99013 6.63997 3.14014 8.06999 3.14014 9.17999V14.83C3.14014 15.93 3.99013 17.37 4.95013 17.91L10.2901 20.88C11.2001 21.39 12.8001 21.39 13.7101 20.88L19.0501 17.91C20.0101 17.37 20.8601 15.94 20.8601 14.83V9.17999C20.8601 8.07999 20.0101 6.63997 19.0501 6.09997L13.7101 3.13C13.2501 2.88 12.6301 2.74999 12.0001 2.74999Z" fill="white" fillOpacity="0.88" />
                                    <path d="M17.0002 13.9905C16.5902 13.9905 16.2502 13.6505 16.2502 13.2405V10.0206L7.13018 4.76055C6.77018 4.55055 6.65016 4.09054 6.86016 3.74054C7.07016 3.38054 7.52018 3.26052 7.88018 3.47052L17.3702 8.95056C17.6002 9.08056 17.7502 9.33052 17.7502 9.60052V13.2606C17.7502 13.6506 17.4102 13.9905 17.0002 13.9905Z" fill="white" fillOpacity="0.88" />
                                </svg>
                                Colis</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/livraisons">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="white" fillOpacity="0.88" />
                                </svg>

                                livraisons</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link " aria-disabled="true">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.31 14.7002L10.81 16.2002L14.81 12.2002" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                Factures</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/blogs">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="white" fill-opacity="0.88" />
                                    <path d="M22 10.7505H18C14.58 10.7505 13.25 9.42048 13.25 6.00048V2.00048C13.25 1.70048 13.43 1.42048 13.71 1.31048C13.99 1.19048 14.31 1.26048 14.53 1.47048L22.53 9.47048C22.74 9.68048 22.81 10.0105 22.69 10.2905C22.57 10.5705 22.3 10.7505 22 10.7505ZM14.75 3.81048V6.00048C14.75 8.58048 15.42 9.25048 18 9.25048H20.19L14.75 3.81048Z" fill="white" fill-opacity="0.88" />
                                    <path d="M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z" fill="white" fill-opacity="0.88" />
                                    <path d="M11 17.75H7C6.59 17.75 6.25 17.41 6.25 17C6.25 16.59 6.59 16.25 7 16.25H11C11.41 16.25 11.75 16.59 11.75 17C11.75 17.41 11.41 17.75 11 17.75Z" fill="white" fill-opacity="0.88" />
                                </svg>

                                Blogs
                            </a>
                        </li>

                    </>}
                    {userRole === "superadmin" && <>

                        <li className="nav-item">
                            <a className="nav-link " aria-disabled="true" href='/dashboard/admins'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.15963 11.62C9.12963 11.62 9.10963 11.62 9.07963 11.62C9.02963 11.61 8.95963 11.61 8.89963 11.62C5.99963 11.53 3.80963 9.25 3.80963 6.44C3.80963 3.58 6.13963 1.25 8.99963 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18963 11.62C9.17963 11.62 9.16963 11.62 9.15963 11.62ZM8.99963 2.75C6.96963 2.75 5.30963 4.41 5.30963 6.44C5.30963 8.44 6.86963 10.05 8.85963 10.12C8.91963 10.11 9.04963 10.11 9.17963 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99963 2.75Z" fill="white" fillOpacity="0.88" />
                                    <path d="M16.5396 11.75C16.5096 11.75 16.4796 11.75 16.4496 11.74C16.0396 11.78 15.6196 11.49 15.5796 11.08C15.5396 10.67 15.7896 10.3 16.1996 10.25C16.3196 10.24 16.4496 10.24 16.5596 10.24C18.0196 10.16 19.1596 8.96 19.1596 7.49C19.1596 5.97 17.9296 4.74 16.4096 4.74C15.9996 4.75 15.6596 4.41 15.6596 4C15.6596 3.59 15.9996 3.25 16.4096 3.25C18.7496 3.25 20.6596 5.16 20.6596 7.5C20.6596 9.8 18.8596 11.66 16.5696 11.75C16.5596 11.75 16.5496 11.75 16.5396 11.75Z" fill="white" fillOpacity="0.88" />
                                    <path d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z" fill="white" fillOpacity="0.88" />
                                    <path d="M18.3396 20.75C17.9896 20.75 17.6796 20.51 17.6096 20.15C17.5296 19.74 17.7896 19.35 18.1896 19.26C18.8196 19.13 19.3996 18.88 19.8496 18.53C20.4196 18.1 20.7296 17.56 20.7296 16.99C20.7296 16.42 20.4196 15.88 19.8596 15.46C19.4196 15.12 18.8696 14.88 18.2196 14.73C17.8196 14.64 17.5596 14.24 17.6496 13.83C17.7396 13.43 18.1396 13.17 18.5496 13.26C19.4096 13.45 20.1596 13.79 20.7696 14.26C21.6996 14.96 22.2296 15.95 22.2296 16.99C22.2296 18.03 21.6896 19.02 20.7596 19.73C20.1396 20.21 19.3596 20.56 18.4996 20.73C18.4396 20.75 18.3896 20.75 18.3396 20.75Z" fill="white" fillOpacity="0.88" />
                                </svg>
                                Admins</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " aria-disabled="true" href='/dashboard/tarif'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.31 14.7002L10.81 16.2002L14.81 12.2002" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Tarif</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/relais">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.7 22.7497H9.29999C4.35999 22.7497 2.25999 20.6397 2.25999 15.7097V11.2197C2.25999 10.8097 2.59999 10.4697 3.00999 10.4697C3.41999 10.4697 3.75999 10.8097 3.75999 11.2197V15.7097C3.75999 19.7997 5.20999 21.2497 9.29999 21.2497H14.69C18.78 21.2497 20.23 19.7997 20.23 15.7097V11.2197C20.23 10.8097 20.57 10.4697 20.98 10.4697C21.39 10.4697 21.73 10.8097 21.73 11.2197V15.7097C21.74 20.6397 19.63 22.7497 14.7 22.7497Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12 12.75C10.9 12.75 9.9 12.32 9.19 11.53C8.48 10.74 8.15 9.71 8.26 8.61L8.93 1.93C8.97 1.55 9.29 1.25 9.68 1.25H14.35C14.74 1.25 15.06 1.54 15.1 1.93L15.77 8.61C15.88 9.71 15.55 10.74 14.84 11.53C14.1 12.32 13.1 12.75 12 12.75ZM10.35 2.75L9.75 8.76C9.68 9.43 9.88 10.06 10.3 10.52C11.15 11.46 12.85 11.46 13.7 10.52C14.12 10.05 14.32 9.42 14.25 8.76L13.65 2.75H10.35Z" fill="white" fillOpacity="0.88" />
                                    <path d="M18.31 12.75C16.28 12.75 14.47 11.11 14.26 9.09L13.56 2.08C13.54 1.87 13.61 1.66 13.75 1.5C13.89 1.34 14.09 1.25 14.31 1.25H17.36C20.3 1.25 21.67 2.48 22.08 5.5L22.36 8.28C22.48 9.46 22.12 10.58 21.35 11.43C20.58 12.28 19.5 12.75 18.31 12.75ZM15.14 2.75L15.76 8.94C15.89 10.19 17.05 11.25 18.31 11.25C19.07 11.25 19.75 10.96 20.24 10.43C20.72 9.9 20.94 9.19 20.87 8.43L20.59 5.68C20.28 3.42 19.55 2.75 17.36 2.75H15.14Z" fill="white" fillOpacity="0.88" />
                                    <path d="M5.64001 12.75C4.45001 12.75 3.37001 12.28 2.60001 11.43C1.83001 10.58 1.47001 9.46 1.59001 8.28L1.86001 5.53C2.28001 2.48 3.65001 1.25 6.59001 1.25H9.64001C9.85001 1.25 10.05 1.34 10.2 1.5C10.35 1.66 10.41 1.87 10.39 2.08L9.69001 9.09C9.48001 11.11 7.67001 12.75 5.64001 12.75ZM6.59001 2.75C4.40001 2.75 3.67001 3.41 3.35001 5.7L3.08001 8.43C3.00001 9.19 3.23001 9.9 3.71001 10.43C4.19001 10.96 4.87001 11.25 5.64001 11.25C6.90001 11.25 8.07001 10.19 8.19001 8.94L8.81001 2.75H6.59001Z" fill="white" fillOpacity="0.88" />
                                    <path d="M14.5 22.75H9.5C9.09 22.75 8.75 22.41 8.75 22V19.5C8.75 17.4 9.9 16.25 12 16.25C14.1 16.25 15.25 17.4 15.25 19.5V22C15.25 22.41 14.91 22.75 14.5 22.75ZM10.25 21.25H13.75V19.5C13.75 18.24 13.26 17.75 12 17.75C10.74 17.75 10.25 18.24 10.25 19.5V21.25Z" fill="white" fillOpacity="0.88" />
                                </svg>


                                Relais</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/sliders">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="white" />
                                    <path d="M9 10.75C7.48 10.75 6.25 9.52 6.25 8C6.25 6.48 7.48 5.25 9 5.25C10.52 5.25 11.75 6.48 11.75 8C11.75 9.52 10.52 10.75 9 10.75ZM9 6.75C8.31 6.75 7.75 7.31 7.75 8C7.75 8.69 8.31 9.25 9 9.25C9.69 9.25 10.25 8.69 10.25 8C10.25 7.31 9.69 6.75 9 6.75Z" fill="white" />
                                    <path d="M2.66999 19.7001C2.42999 19.7001 2.18999 19.5801 2.04999 19.3701C1.81999 19.0301 1.90999 18.5601 2.25999 18.3301L7.18999 15.0201C8.26999 14.2901 9.75999 14.3801 10.74 15.2101L11.07 15.5001C11.57 15.9301 12.42 15.9301 12.91 15.5001L17.07 11.9301C18.13 11.0201 19.8 11.0201 20.87 11.9301L22.5 13.3301C22.81 13.6001 22.85 14.0701 22.58 14.3901C22.31 14.7001 21.84 14.7401 21.52 14.4701L19.89 13.0701C19.39 12.6401 18.54 12.6401 18.04 13.0701L13.88 16.6401C12.82 17.5501 11.15 17.5501 10.08 16.6401L9.74999 16.3501C9.28999 15.9601 8.52999 15.9201 8.01999 16.2701L3.08999 19.5801C2.95999 19.6601 2.80999 19.7001 2.66999 19.7001Z" fill="white" />
                                </svg>
                                Sliders
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/blogs">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="white" fill-opacity="0.88" />
                                    <path d="M22 10.7505H18C14.58 10.7505 13.25 9.42048 13.25 6.00048V2.00048C13.25 1.70048 13.43 1.42048 13.71 1.31048C13.99 1.19048 14.31 1.26048 14.53 1.47048L22.53 9.47048C22.74 9.68048 22.81 10.0105 22.69 10.2905C22.57 10.5705 22.3 10.7505 22 10.7505ZM14.75 3.81048V6.00048C14.75 8.58048 15.42 9.25048 18 9.25048H20.19L14.75 3.81048Z" fill="white" fill-opacity="0.88" />
                                    <path d="M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z" fill="white" fill-opacity="0.88" />
                                    <path d="M11 17.75H7C6.59 17.75 6.25 17.41 6.25 17C6.25 16.59 6.59 16.25 7 16.25H11C11.41 16.25 11.75 16.59 11.75 17C11.75 17.41 11.41 17.75 11 17.75Z" fill="white" fill-opacity="0.88" />
                                </svg>

                                Blogs
                            </a>
                        </li>

                    </>}
                    {userRole === "particulier" && <>

                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/mesColis">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9998 13.2999C11.8698 13.2999 11.7398 13.2699 11.6198 13.1999L2.78985 8.0899C2.42985 7.8799 2.30983 7.41987 2.51983 7.05987C2.72983 6.69987 3.17983 6.57985 3.54983 6.78985L11.9998 11.6799L20.3998 6.81988C20.7598 6.60988 21.2198 6.7399 21.4298 7.0899C21.6398 7.4499 21.5098 7.90987 21.1598 8.11987L12.3899 13.1999C12.2599 13.2599 12.1298 13.2999 11.9998 13.2999Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12 22.36C11.59 22.36 11.25 22.02 11.25 21.61V12.54C11.25 12.13 11.59 11.79 12 11.79C12.41 11.79 12.75 12.13 12.75 12.54V21.61C12.75 22.02 12.41 22.36 12 22.36Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12.0001 22.75C11.1201 22.75 10.2501 22.56 9.56012 22.18L4.22012 19.21C2.77012 18.41 1.64014 16.48 1.64014 14.82V9.16998C1.64014 7.50998 2.77012 5.59002 4.22012 4.78002L9.56012 1.82C10.9301 1.06 13.0701 1.06 14.4401 1.82L19.7801 4.78997C21.2301 5.58997 22.3601 7.51999 22.3601 9.17999V14.83C22.3601 16.49 21.2301 18.41 19.7801 19.22L14.4401 22.18C13.7501 22.56 12.8801 22.75 12.0001 22.75ZM12.0001 2.74999C11.3701 2.74999 10.7501 2.88 10.2901 3.13L4.95013 6.09997C3.99013 6.63997 3.14014 8.06999 3.14014 9.17999V14.83C3.14014 15.93 3.99013 17.37 4.95013 17.91L10.2901 20.88C11.2001 21.39 12.8001 21.39 13.7101 20.88L19.0501 17.91C20.0101 17.37 20.8601 15.94 20.8601 14.83V9.17999C20.8601 8.07999 20.0101 6.63997 19.0501 6.09997L13.7101 3.13C13.2501 2.88 12.6301 2.74999 12.0001 2.74999Z" fill="white" fillOpacity="0.88" />
                                    <path d="M17.0002 13.9905C16.5902 13.9905 16.2502 13.6505 16.2502 13.2405V10.0206L7.13018 4.76055C6.77018 4.55055 6.65016 4.09054 6.86016 3.74054C7.07016 3.38054 7.52018 3.26052 7.88018 3.47052L17.3702 8.95056C17.6002 9.08056 17.7502 9.33052 17.7502 9.60052V13.2606C17.7502 13.6506 17.4102 13.9905 17.0002 13.9905Z" fill="white" fillOpacity="0.88" />
                                </svg>
                                Mes colis</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " href="/dashboard/OffresRecus">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 6.75H10C9.04 6.75 7.25 6.75 7.25 4C7.25 1.25 9.04 1.25 10 1.25H14C14.96 1.25 16.75 1.25 16.75 4C16.75 4.96 16.75 6.75 14 6.75ZM10 2.75C9.01 2.75 8.75 2.75 8.75 4C8.75 5.25 9.01 5.25 10 5.25H14C15.25 5.25 15.25 4.99 15.25 4C15.25 2.75 14.99 2.75 14 2.75H10Z" fill="white" fillOpacity="0.88" />
                                    <path d="M14 22.7502H9C3.38 22.7502 2.25 20.1702 2.25 16.0002V10.0002C2.25 5.44021 3.9 3.49021 7.96 3.28021C8.35 3.26021 8.73 3.57021 8.75 3.99021C8.77 4.41021 8.45 4.75021 8.04 4.77021C5.2 4.93021 3.75 5.78021 3.75 10.0002V16.0002C3.75 19.7002 4.48 21.2502 9 21.2502H14C14.41 21.2502 14.75 21.5902 14.75 22.0002C14.75 22.4102 14.41 22.7502 14 22.7502Z" fill="white" fillOpacity="0.88" />
                                    <path d="M21 15.7504C20.59 15.7504 20.25 15.4104 20.25 15.0004V10.0004C20.25 5.78042 18.8 4.93042 15.96 4.77042C15.55 4.75042 15.23 4.39042 15.25 3.98042C15.27 3.57042 15.63 3.25042 16.04 3.27042C20.1 3.49042 21.75 5.44042 21.75 9.99042V14.9904C21.75 15.4104 21.41 15.7504 21 15.7504Z" fill="white" fillOpacity="0.88" />
                                    <path d="M15 19.75C14.59 19.75 14.25 19.41 14.25 19V16C14.25 15.59 14.59 15.25 15 15.25H18C18.41 15.25 18.75 15.59 18.75 16C18.75 16.41 18.41 16.75 18 16.75H15.75V19C15.75 19.41 15.41 19.75 15 19.75Z" fill="white" fillOpacity="0.88" />
                                    <path d="M21 22.7505C20.81 22.7505 20.62 22.6805 20.47 22.5305L14.51 16.5705C14.22 16.2805 14.22 15.8005 14.51 15.5105C14.8 15.2205 15.28 15.2205 15.57 15.5105L21.53 21.4705C21.82 21.7605 21.82 22.2405 21.53 22.5305C21.38 22.6805 21.19 22.7505 21 22.7505Z" fill="white" fillOpacity="0.88" />
                                </svg>
                                Offres Recus
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/mesLivraisons">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="white" fillOpacity="0.88" />
                                </svg>

                                Mes livraisons</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link disabled" aria-disabled="true">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.31 14.7002L10.81 16.2002L14.81 12.2002" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002" stroke="white" strokeOpacity="0.88" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Mes paiements</a>
                        </li>
                    </>}
                    {userRole === "transporteur" && <>
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard/mesLivraisons">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="white" fillOpacity="0.88" />
                                    <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="white" fillOpacity="0.88" />
                                </svg>

                                Mes livraisons</a>
                        </li>
                    </>}
                </ul >
            </nav >
        </>
    )
}