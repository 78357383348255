import axios from "axios";
import { useEffect, useState } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";

function ModifierTarif() {

    const [pourcentage, setPourcentage] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/tarif`);
                console.log(response.data.value);
                setPourcentage(response.data.value);
            }
            catch (error) { console.log(error) }
        }

        fetchData();
    }, []);

    const updatePourcentage = async () => {
        console.log("updating pourcentage");
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/tarif`, { value: pourcentage });
            console.log(response.data.value);
        } catch (error) {
            console.error("Error updating pourcentage: ", error);
        }
    };
    return (
        <Card className="Card" style={{ maxWidth: "100%" }} >
            <CardHeader className="CardHeader">
                <CardTitle tag="h4">Modifier tarif</CardTitle>
            </CardHeader>
            <CardBody className="pt-0">


                <div className="col-12 col-md-6">
                    <div className="input-group">
                        <input className="form-control" type="number" min={0} value={pourcentage} onChange={(e) => { setPourcentage(Number(e.target.value)) }} />
                        <button className="btn btn-primary w-auto" type="submit" onClick={() => updatePourcentage()}>Modifier</button>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default ModifierTarif;